import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../config/firebase';

interface UserData {
  name: string;
  email: string;
  cpf: string;
  phone: string;
  createdAt: string;
  pixKeys?: {
    key: string;
    type: 'email' | 'cpf' | 'phone' | 'random';
    createdAt: string;
  }[];
}

export const getUserData = async (userId: string): Promise<UserData | null> => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      return userDoc.data() as UserData;
    }
    return null;
  } catch (error) {
    console.error('Error fetching user data:', error);
    return null;
  }
};

export const updateUserProfile = async (
  userId: string,
  data: Partial<UserData>
): Promise<void> => {
  try {
    await updateDoc(doc(db, 'users', userId), data);
  } catch (error) {
    console.error('Error updating user profile:', error);
    throw error;
  }
};

export const updatePixKey = async (
  key: string,
  type: 'email' | 'cpf' | 'phone' | 'random'
): Promise<void> => {
  try {
    const pixKey = {
      key,
      type,
      createdAt: new Date().toISOString()
    };
    // Add implementation for updating PIX key in the database
    console.log('PIX key updated:', pixKey);
  } catch (error) {
    console.error('Error updating PIX key:', error);
    throw error;
  }
};