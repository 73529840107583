import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth } from "../../contexts/AuthContext";
import AffiliateNetwork from './AffiliateNetwork';
import AffiliateSidebar from './AffiliateSidebar';
import AffiliateOverview from './AffiliateOverview';
import AffiliateFinance from './AffiliateFinance';
import AffiliateSettings from './AffiliateSettings';

const AffiliateDashboard: React.FC<{ adminView?: boolean }> = ({ adminView = false }) => {
  const { currentUser } = useAuth();
  const location = useLocation();
  const isFinanceView = location.pathname === '/affiliate/finance';
  const isSettingsView = location.pathname === '/affiliate/settings';
  const isManagementView = location.pathname === '/affiliate/management';

  const renderContent = () => {
    if (isFinanceView) {
      return <AffiliateFinance />;
    }
    if (isSettingsView) {
      return <AffiliateSettings />;
    }
    if (isManagementView) {
      return <AffiliateNetwork />;
    }
    return (
      <div className="space-y-8">
        <AffiliateOverview adminView={adminView} viewedUserId={currentUser?.uid} />
      </div>
    );
  };

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="flex">
        <AffiliateSidebar adminView={adminView} />
        <div className="flex-1 overflow-auto">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default AffiliateDashboard;
