import { 
  addDoc, 
  collection, 
  query, 
  where, 
  getDocs,
  serverTimestamp 
} from 'firebase/firestore';
import { db } from '../config/firebase';
import { createSolarPanelRecord } from './solarPanels';

export interface InvestmentOrder {
  id: string;
  userId: string;
  amount: number;
  startDate: string;
  endDate: string;
  progress: number;
  status: 'active' | 'completed' | 'pending';
  lockPeriod: number;
}

export const createInvestmentOrder = async (
  userId: string,
  amount: number,
  lockPeriod: number
): Promise<InvestmentOrder> => {
  const startDate = new Date();
  const endDate = new Date();
  endDate.setDate(endDate.getDate() + lockPeriod);

  const orderData = {
    userId,
    amount,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
    progress: 0,
    status: 'active' as const,
    lockPeriod,
    createdAt: serverTimestamp()
  };

  try {
    // Create investment order
    const docRef = await addDoc(collection(db, 'investments'), orderData);
    
    // Create solar panel record
    await createSolarPanelRecord(userId, amount);

    return { id: docRef.id, ...orderData };
  } catch (error) {
    console.error('Error creating investment:', error);
    throw error;
  }
};

export const getUserInvestments = async (userId: string): Promise<InvestmentOrder[]> => {
  const q = query(collection(db, 'investments'), where('userId', '==', userId));
  const querySnapshot = await getDocs(q);
  
  return querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  })) as InvestmentOrder[];
};