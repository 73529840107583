import React, { useState, useEffect } from 'react';
import { Clock, TrendingUp, ArrowDownLeft } from 'lucide-react';
import { 
  calculateTimeBasedProfit, 
  calculateProjectedProfit 
} from '../../utils/investment';
import WithdrawalModal from './WithdrawalModal';
import { formatCurrency } from '../../utils/format';

interface ProfitCounterProps {
  startDate: string;
  amount: number;
  lockPeriod?: number;
  adminView?: boolean;
}

const ProfitCounter: React.FC<ProfitCounterProps> = ({ 
  startDate, 
  amount,
  lockPeriod = 30,
  adminView = false
}) => {
  const [currentProfit, setCurrentProfit] = useState(0);
  const [selectedTimeframe, setSelectedTimeframe] = useState<'second' | 'minute' | 'hour' | 'day' | 'week' | 'month'>('second');
  const [showWithdrawalModal, setShowWithdrawalModal] = useState(false);

  useEffect(() => {
    const updateProfit = () => {
      const profit = calculateTimeBasedProfit(startDate, amount, lockPeriod, selectedTimeframe);
      setCurrentProfit(profit);
    };

    updateProfit();
    const interval = setInterval(updateProfit, 1000);

    return () => clearInterval(interval);
  }, [startDate, amount, lockPeriod, selectedTimeframe]);

  const projectedProfit = calculateProjectedProfit(amount, lockPeriod, selectedTimeframe);

  const getTimeframeLabel = () => {
    switch (selectedTimeframe) {
      case 'second': return 'Por Segundo';
      case 'minute': return 'Por Minuto';
      case 'hour': return 'Por Hora';
      case 'day': return 'Por Dia';
      case 'week': return 'Por Semana';
      case 'month': return 'Por Mês';
      default: return '';
    }
  };

  const handleWithdrawalSuccess = () => {
    setShowWithdrawalModal(false);
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center space-x-2">
          <TrendingUp className="h-5 w-5 text-green-500" />
          <span className="font-medium text-gray-700 dark:text-gray-300">Lucro Atual</span>
        </div>
        <select
          value={selectedTimeframe}
          onChange={(e) => setSelectedTimeframe(e.target.value as any)}
          className="text-sm border border-gray-300 dark:border-gray-600 rounded-md px-2 py-1 bg-white dark:bg-gray-700 text-gray-700 dark:text-gray-300"
        >
          <option value="second">Por Segundo</option>
          <option value="minute">Por Minuto</option>
          <option value="hour">Por Hora</option>
          <option value="day">Por Dia</option>
          <option value="week">Por Semana</option>
          <option value="month">Por Mês</option>
        </select>
      </div>

      <div className="space-y-6">
        {/* Current Profit */}
        <div className="bg-gray-50 dark:bg-gray-700/50 rounded-lg p-4">
          <p className="text-sm text-gray-600 dark:text-gray-400 mb-1">
            Lucro Acumulado {getTimeframeLabel()}
          </p>
          <p className="text-2xl font-bold text-green-500">
            {formatCurrency(currentProfit)}
          </p>
        </div>

        {/* Projected Profit */}
        <div className="bg-gray-50 dark:bg-gray-700/50 rounded-lg p-4">
          <p className="text-sm text-gray-600 dark:text-gray-400 mb-1">
            Projeção de Lucro {getTimeframeLabel()}
          </p>
          <p className="text-2xl font-bold text-blue-500">
            {formatCurrency(projectedProfit)}
          </p>
        </div>
      </div>

      <div className="mt-6 flex items-center justify-between">
        <div className="flex items-center text-sm text-gray-500 dark:text-gray-400">
          <Clock className="h-4 w-4 mr-1" />
          <span>Desde {new Date(startDate).toLocaleDateString('pt-BR')}</span>
        </div>
        {!adminView && (
          <button
            onClick={() => setShowWithdrawalModal(true)}
            className="flex items-center space-x-1 px-3 py-1 bg-green-500 hover:bg-green-600 text-white rounded-lg transition-colors"
          >
            <ArrowDownLeft className="h-4 w-4" />
            <span>Sacar</span>
          </button>
        )}
      </div>

      {showWithdrawalModal && (
        <WithdrawalModal
          amount={currentProfit}
          onClose={() => setShowWithdrawalModal(false)}
          onSuccess={handleWithdrawalSuccess}
        />
      )}
    </div>
  );
};

export default ProfitCounter;