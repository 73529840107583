import { 
  doc, 
  setDoc,
  getDoc,
  collection, 
  query, 
  where, 
  getDocs,
  updateDoc,
  serverTimestamp,
  Timestamp 
} from 'firebase/firestore';
import { db } from '../config/firebase';

export interface AffiliateData {
  id: string;
  userId: string;
  name: string;
  email: string;
  whatsapp: string;
  socialNetworks: {
    type: string;
    link: string;
  }[];
  status: 'pending' | 'approved' | 'rejected';
  referralCode?: string;
  metrics: {
    totalReferrals: number;
    activeReferrals: number;
    totalEarnings: number;
    pendingEarnings: number;
    clickCount: number;
    conversionRate: number;
  };
  referrals: {
    level1: string[];
    level2: string[];
    level3: string[];
  };
  earnings: {
    available: number;
    total: number;
    history: {
      amount: number;
      type: 'investment' | 'credit';
      date: string;
      referralId: string;
    }[];
  };
  createdAt: string;
  updatedAt: string;
}

export interface AffiliateReferral {
  id: string;
  userId: string;
  name: string;
  email: string;
  level: 1 | 2 | 3;
  status: 'active' | 'inactive';
  investmentAmount: number;
  creditPlan: boolean;
  registrationDate: string;
}

export interface WithdrawalMethod {
  type: 'pix' | 'usdt';
  key: string;
  updatedAt: string;
}

// Submeter aplicação de afiliado
export const submitAffiliateApplication = async (data: {
  userId: string;
  name: string;
  email: string;
  whatsapp: string;
  socialNetworks: { type: string; link: string }[];
}) => {
  if (!data.userId || data.userId.trim() === '') {
    throw new Error('userId inválido.');
  }

  try {
    console.log('Submetendo aplicação para:', data.userId);

    const timestamp = new Date().toISOString();
    const affiliateRef = doc(collection(db, 'affiliates'));

    const affiliateData = {
      ...data,
      status: 'pending',
      metrics: {
        totalReferrals: 0,
        activeReferrals: 0,
        totalEarnings: 0,
        pendingEarnings: 0,
        clickCount: 0,
        conversionRate: 0,
      },
      referrals: {
        level1: [],
        level2: [],
        level3: [],
      },
      earnings: {
        available: 0,
        total: 0,
        history: [],
      },
      createdAt: timestamp,
      updatedAt: timestamp,
    };

    await setDoc(affiliateRef, affiliateData);

    const userRef = doc(db, 'users', data.userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      throw new Error('Usuário não encontrado no Firestore.');
    }

    await updateDoc(userRef, {
      isAffiliate: true,
      affiliateStatus: 'pending',
      updatedAt: timestamp,
    });

    return affiliateRef.id;
  } catch (error) {
    console.error('Erro ao submeter aplicação de afiliado:', error);
    throw error;
  }
};

// Buscar dados do afiliado
export const getAffiliateData = async (userId: string): Promise<AffiliateData | null> => {
  if (!userId || userId.trim() === '') {
    throw new Error('userId inválido.');
  }

  try {
    const affiliatesRef = collection(db, 'affiliates');
    const q = query(affiliatesRef, where('userId', '==', userId));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) return null;

    const affiliateDoc = querySnapshot.docs[0];
    const data = affiliateDoc.data();

    return {
      id: affiliateDoc.id,
      ...data,
      createdAt: data.createdAt instanceof Timestamp 
        ? data.createdAt.toDate().toISOString() 
        : data.createdAt,
      updatedAt: data.updatedAt instanceof Timestamp 
        ? data.updatedAt.toDate().toISOString() 
        : data.updatedAt
    } as AffiliateData;
  } catch (error) {
    console.error('Erro ao buscar dados do afiliado:', error);
    return null;
  }
};

// Buscar referenciados do afiliado
export const getAffiliateReferrals = async (affiliateId: string): Promise<AffiliateReferral[]> => {
  if (!affiliateId || affiliateId.trim() === '') {
    throw new Error('affiliateId inválido.');
  }

  try {
    const affiliateDoc = await getDoc(doc(db, 'affiliates', affiliateId));
    if (!affiliateDoc.exists()) return [];

    const data = affiliateDoc.data();
    if (!data.referrals) {
      return [];
    }

    const allReferrals = [
      ...(data.referrals.level1 || []).map(id => ({ id, level: 1 })),
      ...(data.referrals.level2 || []).map(id => ({ id, level: 2 })),
      ...(data.referrals.level3 || []).map(id => ({ id, level: 3 }))
    ];

    const referrals: AffiliateReferral[] = [];

    for (const ref of allReferrals) {
      try {
        const userDoc = await getDoc(doc(db, 'users', ref.id));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          referrals.push({
            id: ref.id,
            userId: ref.id,
            name: userData.name || 'Usuário',
            email: userData.email || '',
            level: ref.level as 1 | 2 | 3,
            status: userData.status || 'inactive',
            investmentAmount: userData.investmentAmount || 0,
            creditPlan: userData.hasCreditPlan || false,
            registrationDate: userData.createdAt || new Date().toISOString()
          });
        }
      } catch (error) {
        console.error(`Erro ao buscar usuário ${ref.id}:`, error);
        continue;
      }
    }

    return referrals;
  } catch (error) {
    console.error('Erro ao buscar referenciados do afiliado:', error);
    return [];
  }
};

// Atualizar método de retirada
export const updateWithdrawalMethod = async (userId: string, method: Omit<WithdrawalMethod, 'updatedAt'>) => {
  if (!userId || userId.trim() === '') {
    throw new Error('userId inválido.');
  }

  try {
    const affiliateRef = doc(db, 'affiliates', userId);
    await updateDoc(affiliateRef, {
      withdrawalMethod: {
        ...method,
        updatedAt: serverTimestamp(),
      },
    });
  } catch (error) {
    console.error('Erro ao atualizar método de retirada:', error);
    throw error;
  }
};

// Buscar método de retirada
export const getWithdrawalMethod = async (userId: string): Promise<WithdrawalMethod | null> => {
  if (!userId || userId.trim() === '') {
    throw new Error('userId inválido.');
  }

  try {
    const affiliateRef = doc(db, 'affiliates', userId);
    const docSnap = await getDoc(affiliateRef);

    if (docSnap.exists() && docSnap.data().withdrawalMethod) {
      return docSnap.data().withdrawalMethod as WithdrawalMethod;
    }

    return null;
  } catch (error) {
    console.error('Erro ao buscar método de retirada:', error);
    throw error;
  }
};

// Buscar aplicações pendentes
export const getPendingApplications = async () => {
  try {
    const q = query(collection(db, 'affiliates'), where('status', '==', 'pending'));
    const querySnapshot = await getDocs(q);

    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Erro ao buscar aplicações pendentes:', error);
    return [];
  }
};

// Aprovar aplicação de afiliado
export const approveAffiliateApplication = async (applicationId: string, userId: string) => {
  if (!applicationId || !userId) {
    throw new Error('Parâmetros inválidos.');
  }

  try {
    const timestamp = new Date().toISOString();
    const referralCode = generateReferralCode(userId);

    // Verificar existência do documento do usuário
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);

    if (!userDoc.exists()) {
      throw new Error(`Usuário não encontrado no Firestore: ${userId}`);
    }

    // Atualizar documento do afiliado
    await updateDoc(doc(db, 'affiliates', applicationId), {
      status: 'approved',
      referralCode,
      updatedAt: timestamp,
    });

    // Atualizar documento do usuário
    await updateDoc(userRef, {
      affiliateStatus: 'approved',
      referralCode,
      updatedAt: timestamp,
    });

  } catch (error) {
    console.error('Erro ao aprovar aplicação de afiliado:', error);
    throw error;
  }
};


// Rejeitar aplicação de afiliado
export const rejectAffiliateApplication = async (applicationId: string, userId: string) => {
  if (!applicationId || !userId) {
    throw new Error('Parâmetros inválidos.');
  }

  try {
    const timestamp = new Date().toISOString();

    await updateDoc(doc(db, 'affiliates', applicationId), {
      status: 'rejected',
      updatedAt: timestamp
    });

    await updateDoc(doc(db, 'users', userId), {
      affiliateStatus: 'rejected',
      updatedAt: timestamp
    });
  } catch (error) {
    console.error('Erro ao rejeitar aplicação de afiliado:', error);
    throw error;
  }
};

// Gerar código de referência
export const generateReferralCode = (userId: string): string => {
  const prefix = 'HK';
  const randomPart = Math.random().toString(36).substring(2, 7).toUpperCase();
  const userPart = userId.substring(0, 4);
  return `${prefix}${randomPart}${userPart}`;
};
