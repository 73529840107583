import React, { useState } from 'react';
import { MoreVertical, Eye, Lock, Unlock, Edit } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { updateUserStatus } from '../../services/admin';
import type { User } from '../../services/admin';

interface UserActionMenuProps {
  user: User;
  onStatusChange: () => void;
}

const UserActionMenu: React.FC<UserActionMenuProps> = ({ user, onStatusChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleAction = async (action: 'view' | 'block' | 'edit') => {
    try {
      setLoading(true);
      
      switch (action) {
        case 'view':
          navigate(`/admin/users/${user.id}/dashboard`);
          break;
        case 'block':
          await updateUserStatus(user.id, user.status === 'active' ? 'inactive' : 'active');
          onStatusChange();
          break;
        case 'edit':
          navigate(`/admin/users/${user.id}/edit`);
          break;
      }
    } catch (error) {
      console.error('Error performing action:', error);
    } finally {
      setLoading(false);
      setIsOpen(false);
    }
  };

  return (
    <div className="relative">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
        disabled={loading}
      >
        <MoreVertical className="h-5 w-5" />
      </button>

      {isOpen && (
        <>
          {/* Backdrop */}
          <div
            className="fixed inset-0 z-10"
            onClick={() => setIsOpen(false)}
          />

          {/* Menu */}
          <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white dark:bg-gray-700 ring-1 ring-black ring-opacity-5 z-20">
            <div className="py-1">
              <button
                onClick={() => handleAction('view')}
                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600"
              >
                <Eye className="h-4 w-4 mr-3" />
                Visualizar Dashboard
              </button>

              <button
                onClick={() => handleAction('block')}
                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600"
              >
                {user.status === 'active' ? (
                  <>
                    <Lock className="h-4 w-4 mr-3" />
                    Bloquear Usuário
                  </>
                ) : (
                  <>
                    <Unlock className="h-4 w-4 mr-3" />
                    Desbloquear Usuário
                  </>
                )}
              </button>

              <button
                onClick={() => handleAction('edit')}
                className="flex items-center w-full px-4 py-2 text-sm text-gray-700 dark:text-gray-200 hover:bg-gray-100 dark:hover:bg-gray-600"
              >
                <Edit className="h-4 w-4 mr-3" />
                Editar Informações
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default UserActionMenu;