import React from 'react';

const LoadingAnimation = () => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="relative">
        <div className="w-16 h-16 border-4 border-purple-200 dark:border-purple-900 rounded-full"></div>
        <div className="w-16 h-16 border-4 border-purple-600 dark:border-purple-400 rounded-full absolute top-0 left-0 animate-spin border-t-transparent"></div>
      </div>
      <div className="mt-4 space-y-2">
        <div className="h-2 w-48 bg-purple-200 dark:bg-purple-900/50 rounded-full overflow-hidden">
          <div className="h-full w-1/2 bg-purple-600 dark:bg-purple-400 rounded-full animate-pulse"></div>
        </div>
        <div className="h-2 w-32 bg-purple-200 dark:bg-purple-900/50 rounded-full overflow-hidden">
          <div className="h-full w-1/3 bg-purple-600 dark:bg-purple-400 rounded-full animate-pulse"></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingAnimation;