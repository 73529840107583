import React, { useState, useEffect, useRef } from 'react';
import { Send, Paperclip, Smile, CheckCircle, RefreshCw } from 'lucide-react';
import { 
  subscribeToMessages, 
  sendMessage, 
  markChatAsRead,
  finalizeChat,
  reopenChat
} from '../../../services/chat';
import type { Message } from '../../../types/chat';

interface ChatWindowProps {
  chatId: string | null;
  userName?: string;
  archived?: boolean;
}

const ChatWindow: React.FC<ChatWindowProps> = ({ chatId, userName, archived = false }) => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState<Message[]>([]);
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let unsubscribe: (() => void) | undefined;

    if (chatId) {
      setLoading(true);
      unsubscribe = subscribeToMessages(chatId, (newMessages) => {
        setMessages(newMessages);
        setLoading(false);
        // Mark messages as read when support agent views them
        markChatAsRead(chatId).catch(console.error);
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [chatId]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSend = async () => {
    if (!message.trim() || !chatId) return;

    try {
      await sendMessage(chatId, {
        content: message,
        sender: 'support',
        timestamp: new Date().toISOString()
      });
      setMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleFinalizeChat = async () => {
    if (!chatId) return;
    try {
      await finalizeChat(chatId);
    } catch (error) {
      console.error('Error finalizing chat:', error);
    }
  };

  const handleReopenChat = async () => {
    if (!chatId) return;
    try {
      await reopenChat(chatId);
    } catch (error) {
      console.error('Error reopening chat:', error);
    }
  };

  if (!chatId) {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm h-[600px] flex items-center justify-center">
        <p className="text-gray-500 dark:text-gray-400">
          Selecione uma conversa para começar
        </p>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm h-[600px] flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm h-[600px] flex flex-col">
      {/* Chat Header */}
      <div className="p-4 border-b border-gray-200 dark:border-gray-700">
        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <div className="w-10 h-10 rounded-full bg-gray-200 dark:bg-gray-700 flex items-center justify-center">
              <span className="text-gray-600 dark:text-gray-300 font-medium">
                {userName?.charAt(0) || '?'}
              </span>
            </div>
            <div>
              <h3 className="text-sm font-medium text-gray-900 dark:text-white">
                {userName || 'Cliente'}
              </h3>
              <p className="text-xs text-gray-500 dark:text-gray-400">
                {archived ? 'Chat Arquivado' : 'Chat Ativo'}
              </p>
            </div>
          </div>
          {archived ? (
            <button
              onClick={handleReopenChat}
              className="flex items-center space-x-1 px-3 py-1 bg-green-100 text-green-700 dark:bg-green-900/20 dark:text-green-400 rounded-lg hover:bg-green-200 dark:hover:bg-green-900/40 transition-colors"
            >
              <RefreshCw className="h-4 w-4" />
              <span>Reabrir Chat</span>
            </button>
          ) : (
            <button
              onClick={handleFinalizeChat}
              className="flex items-center space-x-1 px-3 py-1 bg-purple-100 text-purple-700 dark:bg-purple-900/20 dark:text-purple-400 rounded-lg hover:bg-purple-200 dark:hover:bg-purple-900/40 transition-colors"
            >
              <CheckCircle className="h-4 w-4" />
              <span>Finalizar Chat</span>
            </button>
          )}
        </div>
      </div>

      {/* Messages */}
      <div className="flex-1 overflow-y-auto p-4 space-y-4">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`flex ${msg.sender === 'support' ? 'justify-end' : 'justify-start'}`}
          >
            <div
              className={`max-w-[70%] rounded-lg p-3 ${
                msg.sender === 'support'
                  ? 'bg-purple-600 text-white'
                  : msg.sender === 'system'
                  ? 'bg-blue-100 dark:bg-blue-900/20 text-blue-800 dark:text-blue-200'
                  : 'bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white'
              }`}
            >
              <p className="text-sm">{msg.content}</p>
              <span className="text-xs opacity-75 mt-1 block">
                {new Date(msg.timestamp).toLocaleTimeString('pt-BR', {
                  hour: '2-digit',
                  minute: '2-digit'
                })}
              </span>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      {/* Input Area */}
      {!archived && (
        <div className="p-4 border-t border-gray-200 dark:border-gray-700">
          <div className="flex items-center space-x-2">
            <button className="p-2 text-gray-500 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300">
              <Paperclip className="h-5 w-5" />
            </button>
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Digite sua mensagem..."
              className="flex-1 px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:text-white"
            />
            <button className="p-2 text-gray-500 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300">
              <Smile className="h-5 w-5" />
            </button>
            <button
              onClick={handleSend}
              disabled={!message.trim()}
              className="p-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <Send className="h-5 w-5" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatWindow;