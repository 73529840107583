import React, { useState, useEffect } from 'react';
import { History, ArrowUpRight, ArrowDownLeft, Clock, TrendingUp } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { getUserInvestments, type InvestmentOrder } from '../../services/investment';
import ProfitCounter from './ProfitCounter';

interface InvestmentOrdersProps {
  refreshTrigger?: number;
  userId?: string;
  adminView?: boolean;
}

const InvestmentOrders: React.FC<InvestmentOrdersProps> = ({ 
  refreshTrigger,
  userId,
  adminView = false
}) => {
  const [orders, setOrders] = useState<InvestmentOrder[]>([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchOrders = async () => {
      if (!currentUser && !userId) return;
      
      try {
        setLoading(true);
        const userOrders = await getUserInvestments(userId || currentUser!.uid);
        setOrders(userOrders);
      } catch (error) {
        console.error('Error fetching orders:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [currentUser, userId, refreshTrigger]);

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString('pt-BR');
  };

  const formatAmount = (amount: number) => {
    return amount.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };

  if (loading) {
    return (
      <div className="mt-8">
        <h2 className="text-lg sm:text-xl font-semibold mb-4">Minhas Ordens</h2>
        <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-sm p-4">
          <p className="text-gray-500 dark:text-gray-400">Carregando ordens...</p>
        </div>
      </div>
    );
  }

  return (
    <div className="mt-8">
      <h2 className="text-lg sm:text-xl font-semibold mb-4">
        {adminView ? 'Ordens do Usuário' : 'Minhas Ordens'}
      </h2>
      <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-sm overflow-hidden">
        <div className="divide-y divide-gray-200 dark:divide-gray-700">
          {orders.length === 0 ? (
            <div className="p-4 text-center text-gray-500 dark:text-gray-400">
              Nenhuma ordem de investimento encontrada
            </div>
          ) : (
            orders.map((order) => (
              <div key={order.id} className="p-4">
                <div className="flex justify-between items-start mb-3">
                  <div className="flex items-center space-x-4">
                    <div className="w-10 h-10 bg-purple-100 dark:bg-purple-900/50 rounded-lg flex items-center justify-center">
                      <TrendingUp className="h-5 w-5 text-purple-600 dark:text-purple-400" />
                    </div>
                    <div>
                      <p className="font-medium text-gray-900 dark:text-white">R$ {formatAmount(order.amount)}</p>
                      <div className="flex items-center space-x-2 text-sm text-gray-500 dark:text-gray-400">
                        <Clock className="h-4 w-4" />
                        <span>
                          {formatDate(order.startDate)} - {formatDate(order.endDate)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <span className={`px-2 py-1 text-xs rounded-full ${
                    order.status === 'active' ? 'bg-green-100 text-green-800 dark:bg-green-900/50 dark:text-green-400' :
                    order.status === 'completed' ? 'bg-blue-100 text-blue-800 dark:bg-blue-900/50 dark:text-blue-400' :
                    'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/50 dark:text-yellow-400'
                  }`}>
                    {order.status === 'active' ? 'Ativo' :
                     order.status === 'completed' ? 'Concluído' : 'Pendente'}
                  </span>
                </div>

                {order.status === 'active' && (
                  <div className="mt-4 mb-4">
                    <ProfitCounter
                      startDate={order.startDate}
                      amount={order.amount}
                      adminView={adminView}
                    />
                  </div>
                )}

                <div className="w-full bg-gray-200 dark:bg-gray-700 rounded-full h-2">
                  <div
                    className="bg-purple-600 dark:bg-purple-400 h-2 rounded-full transition-all duration-500"
                    style={{ width: `${order.progress}%` }}
                  />
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default InvestmentOrders;