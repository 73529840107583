import React from 'react';
import { DollarSign, CreditCard, Users, TrendingUp } from 'lucide-react';
import { formatCurrency } from '../../utils/format';

interface FinancialMetricsProps {
  data: {
    totalRevenue: number;
    totalInvestments: number;
    creditContracts: number;
    affiliateCommissions: number;
  };
}

const FinancialMetrics: React.FC<FinancialMetricsProps> = ({ data }) => {
  const metrics = [
    {
      title: 'Faturamento Total',
      value: formatCurrency(data.totalRevenue || 0),
      icon: DollarSign,
      color: 'text-green-600 dark:text-green-400 bg-green-100 dark:bg-green-900/50'
    },
    {
      title: 'Total Investido',
      value: formatCurrency(data.totalInvestments || 0),
      icon: TrendingUp,
      color: 'text-blue-600 dark:text-blue-400 bg-blue-100 dark:bg-blue-900/50'
    },
    {
      title: 'Contratos de Crédito',
      value: data.creditContracts || 0,
      icon: CreditCard,
      color: 'text-purple-600 dark:text-purple-400 bg-purple-100 dark:bg-purple-900/50'
    },
    {
      title: 'Comissões de Afiliados',
      value: formatCurrency(data.affiliateCommissions || 0),
      icon: Users,
      color: 'text-yellow-600 dark:text-yellow-400 bg-yellow-100 dark:bg-yellow-900/50'
    }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
      {metrics.map((metric, index) => {
        const Icon = metric.icon;
        return (
          <div key={index} className="bg-white dark:bg-gray-800 p-6 rounded-lg shadow-sm">
            <div className="flex items-center justify-between">
              <div className={`p-3 rounded-lg ${metric.color}`}>
                <Icon className="h-6 w-6" />
              </div>
              <span className="text-sm text-gray-500 dark:text-gray-400">
                Últimos 30 dias
              </span>
            </div>
            <div className="mt-4">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                {metric.value}
              </h3>
              <p className="text-sm text-gray-600 dark:text-gray-400">
                {metric.title}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default FinancialMetrics;