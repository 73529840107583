import React, { useState } from 'react';
import { Bell, Globe, Mail, MessageSquare } from 'lucide-react';
import DashboardLayout from '../dashboard/DashboardLayout';
import Logo from '../Logo';

interface Setting {
  id: string;
  enabled: boolean;
}

const SettingsContent = () => {
  const [language, setLanguage] = useState('pt-BR');
  const [settings, setSettings] = useState<Setting[]>([
    { id: 'notifications', enabled: true },
    { id: 'newsletter', enabled: true },
    { id: 'marketing', enabled: false }
  ]);

  const handleSettingToggle = (settingId: string) => {
    setSettings(prevSettings =>
      prevSettings.map(setting =>
        setting.id === settingId
          ? { ...setting, enabled: !setting.enabled }
          : setting
      )
    );
  };

  const isEnabled = (settingId: string) => {
    return settings.find(s => s.id === settingId)?.enabled || false;
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="mb-8 flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Configurações</h1>
        <Logo size="sm" />
      </div>

      <div className="space-y-6">
        {/* Language Settings */}
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-6">
          <div className="flex items-center space-x-3 mb-4">
            <Globe className="h-6 w-6 text-purple-600 dark:text-purple-400" />
            <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Idioma</h2>
          </div>
          <div className="max-w-xs">
            <select
              value={language}
              onChange={(e) => setLanguage(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-purple-500 focus:ring-purple-500 dark:bg-gray-700 dark:text-white"
            >
              <option value="pt-BR">Português (Brasil)</option>
              <option value="en">English</option>
              <option value="es">Español</option>
            </select>
          </div>
        </div>

        {/* Notification Settings */}
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-6">
          <div className="flex items-center space-x-3 mb-6">
            <Bell className="h-6 w-6 text-purple-600 dark:text-purple-400" />
            <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Notificações</h2>
          </div>

          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div className="flex-1">
                <label className="flex items-center cursor-pointer">
                  <div className="ml-3">
                    <span className="text-sm font-medium text-gray-900 dark:text-white">Notificações Push</span>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      Receba alertas sobre suas transações e investimentos
                    </p>
                  </div>
                </label>
              </div>
              <button
                onClick={() => handleSettingToggle('notifications')}
                className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none ${
                  isEnabled('notifications') ? 'bg-purple-600' : 'bg-gray-200 dark:bg-gray-700'
                }`}
              >
                <span
                  className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                    isEnabled('notifications') ? 'translate-x-5' : 'translate-x-0'
                  }`}
                />
              </button>
            </div>
          </div>
        </div>

        {/* Communication Preferences */}
        <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-6">
          <div className="flex items-center space-x-3 mb-6">
            <Mail className="h-6 w-6 text-purple-600 dark:text-purple-400" />
            <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Comunicação</h2>
          </div>

          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div className="flex-1">
                <label className="flex items-center cursor-pointer">
                  <div className="ml-3">
                    <span className="text-sm font-medium text-gray-900 dark:text-white">Newsletter</span>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      Receba novidades e atualizações sobre nossos serviços
                    </p>
                  </div>
                </label>
              </div>
              <button
                onClick={() => handleSettingToggle('newsletter')}
                className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none ${
                  isEnabled('newsletter') ? 'bg-purple-600' : 'bg-gray-200 dark:bg-gray-700'
                }`}
              >
                <span
                  className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                    isEnabled('newsletter') ? 'translate-x-5' : 'translate-x-0'
                  }`}
                />
              </button>
            </div>

            <div className="flex items-center justify-between">
              <div className="flex-1">
                <label className="flex items-center cursor-pointer">
                  <div className="ml-3">
                    <span className="text-sm font-medium text-gray-900 dark:text-white">Campanhas de Marketing</span>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      Receba ofertas especiais e promoções exclusivas
                    </p>
                  </div>
                </label>
              </div>
              <button
                onClick={() => handleSettingToggle('marketing')}
                className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none ${
                  isEnabled('marketing') ? 'bg-purple-600' : 'bg-gray-200 dark:bg-gray-700'
                }`}
              >
                <span
                  className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                    isEnabled('marketing') ? 'translate-x-5' : 'translate-x-0'
                  }`}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const SettingsPage = () => {
  return (
    <DashboardLayout>
      <SettingsContent />
    </DashboardLayout>
  );
};

export default SettingsPage;