import React, { useState, useEffect } from 'react';
import { Check, X, UserCheck } from 'lucide-react';
import { getPendingApplications, approveAffiliateApplication, rejectAffiliateApplication } from '../../services/affiliate';
import type { AffiliateApplication } from '../../services/affiliate';

const PendingApprovals = () => {
  const [applications, setApplications] = useState<(AffiliateApplication & { id: string })[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const fetchPendingApplications = async () => {
    try {
      setLoading(true);
      setError(null);
      const pendingApps = await getPendingApplications();
      setApplications(pendingApps);
    } catch (error) {
      console.error('Error fetching pending applications:', error);
      setError('Erro ao carregar aplicações pendentes');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPendingApplications();
  }, []);

  const handleApprove = async (applicationId: string, userId: string) => {
  console.log('Aprovando aplicação:', { applicationId, userId });
  
  try {
    await approveAffiliateApplication(applicationId, userId);
    console.log('Aplicação aprovada com sucesso.');
  } catch (error) {
    console.error('Erro ao aprovar aplicação:', error);
  }
  };


  const handleReject = async (applicationId: string, userId: string) => {
    try {
      setError(null);
      await rejectAffiliateApplication(applicationId, userId);
      await fetchPendingApplications();
    } catch (error) {
      console.error('Error rejecting application:', error);
      setError('Erro ao rejeitar aplicação');
    }
  };

  if (loading) {
    return (
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm p-6">
        <div className="flex justify-center items-center h-32">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm">
      <div className="p-6">
        <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
          Aprovações Pendentes
        </h2>

        {error && (
          <div className="mb-4 p-3 bg-red-50 dark:bg-red-900/50 text-red-600 dark:text-red-200 rounded-md">
            {error}
          </div>
        )}

        {applications.length === 0 ? (
          <div className="text-center text-gray-500 dark:text-gray-400 py-8">
            Nenhuma aprovação pendente
          </div>
        ) : (
          <div className="space-y-4">
            {applications.map((application) => (
              <div key={application.id} className="flex items-center justify-between p-4 bg-gray-50 dark:bg-gray-700/50 rounded-lg">
                <div className="flex items-center">
                  <div className="p-2 bg-purple-100 dark:bg-purple-900/50 rounded-full">
                    <UserCheck className="h-5 w-5 text-purple-600 dark:text-purple-400" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900 dark:text-white">
                      {application.name}
                    </p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      {application.email}
                    </p>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <button
                    onClick={() => handleApprove(application.id, application.userId)}
                    className="p-2 bg-green-100 text-green-600 rounded-full hover:bg-green-200 dark:bg-green-900/50 dark:text-green-400 transition-colors"
                  >
                    <Check className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() => handleReject(application.id, application.userId)}
                    className="p-2 bg-red-100 text-red-600 rounded-full hover:bg-red-200 dark:bg-red-900/50 dark:text-red-400 transition-colors"
                  >
                    <X className="h-5 w-5" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default PendingApprovals;