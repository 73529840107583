export default {
  translation: {
    common: {
      welcome: 'Welcome',
      loading: 'Loading...',
      error: 'Error',
      success: 'Success',
      save: 'Save',
      cancel: 'Cancel',
      search: 'Search',
      noResults: 'No results found',
      menu: 'Menu'
    },
    auth: {
      login: 'Login',
      register: 'Register',
      logout: 'Logout',
      email: 'Email',
      password: 'Password',
      confirmPassword: 'Confirm password',
      forgotPassword: 'Forgot password?',
      name: 'Full name',
      phone: 'Phone',
      country: 'Country',
      birthDate: 'Birth Date',
      motherName: 'Mother\'s Name'
    },
    dashboard: {
      overview: 'Overview',
      investments: 'Investments',
      transactions: 'Transactions',
      settings: 'Settings',
      security: 'Security',
      support: 'Support',
      profile: 'Profile',
      totalInvestment: 'Total Investment',
      availableBalance: 'Available Balance',
      pendingTransactions: 'Pending Transactions'
    },
    admin: {
      users: 'Users',
      approvals: 'Approvals',
      withdrawals: 'Withdrawals',
      reports: 'Reports',
      metrics: {
        revenue: 'Total Revenue',
        investments: 'Total Invested',
        contracts: 'Credit Contracts',
        commissions: 'Affiliate Commissions'
      }
    },
    affiliate: {
      dashboard: 'Affiliate Dashboard',
      network: 'My Network',
      earnings: 'Earnings',
      referrals: 'Referrals',
      marketing: 'Marketing',
      commission: 'Commission',
      metrics: {
        totalReferrals: 'Total Referrals',
        activeReferrals: 'Active Referrals',
        earnings: 'Total Earnings',
        clickCount: 'Link Clicks'
      }
    }
  }
};