import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Upload, Briefcase, Eye, EyeOff } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { useUser } from '../../contexts/UserContext';
import Logo from '../Logo';
import SocialNetworkButton, { type SocialNetwork } from './SocialNetworkButton';
import { submitAffiliateApplication } from '../../services/affiliate';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../config/firebase';

interface SocialNetworkInput {
  type: SocialNetwork;
  link: string;
}

interface FormData {
  email: string;
  whatsapp: string;
  password: string;
  confirmPassword: string;
  socialNetworks: SocialNetworkInput[];
  results: File[];
  referralCode?: string;
}

const CareersForm = () => {
  const [formData, setFormData] = useState<FormData>({
    email: '',
    whatsapp: '',
    password: '',
    confirmPassword: '',
    socialNetworks: [],
    results: [],
    referralCode: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { currentUser } = useAuth();
  const { userData } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (userData?.email) {
      setFormData((prev) => ({ ...prev, email: userData.email }));
    }
  }, [userData]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const ref = queryParams.get('ref');
    if (ref) {
      setFormData((prev) => ({ ...prev, referralCode: ref }));
    }
  }, [location]);

  const handleSocialNetworkAdd = (type: SocialNetwork) => {
    if (!formData.socialNetworks.some((network) => network.type === type)) {
      setFormData((prev) => ({
        ...prev,
        socialNetworks: [...prev.socialNetworks, { type, link: '' }]
      }));
    }
  };

  const handleSocialNetworkChange = (index: number, link: string) => {
    const newNetworks = [...formData.socialNetworks];
    newNetworks[index].link = link;
    setFormData((prev) => ({
      ...prev,
      socialNetworks: newNetworks
    }));
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFormData((prev) => ({
        ...prev,
        results: [...Array.from(e.target.files!)]
      }));
    }
  };

  const validateForm = () => {
    if (!formData.email || !formData.whatsapp || !formData.password) {
      throw new Error('Email, WhatsApp e senha são obrigatórios');
    }

    if (formData.password.length < 6) {
      throw new Error('A senha deve ter pelo menos 6 caracteres');
    }

    if (formData.password !== formData.confirmPassword) {
      throw new Error('As senhas não correspondem');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setLoading(true);

    try {
      validateForm();

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );

      const applicationData = {
        userId: userCredential.user.uid,
        name: userData?.name || '',
        email: formData.email,
        whatsapp: formData.whatsapp,
        socialNetworks: formData.socialNetworks,
        referralCode: formData.referralCode
      };

      await submitAffiliateApplication(applicationData);
      setSubmitted(true);
    } catch (error) {
      setError(error instanceof Error ? error.message : 'Erro ao enviar cadastro');
      console.error('Error submitting application:', error);
    } finally {
      setLoading(false);
    }
  };

  if (submitted) {
    return (
      <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-md w-full space-y-8 bg-white dark:bg-gray-800 p-8 rounded-xl shadow-lg">
          <div className="text-center">
            <Logo size="lg" className="mx-auto mb-6" />
            <h2 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">
              Obrigado pelo seu interesse!
            </h2>
            <p className="text-gray-600 dark:text-gray-400 mb-6">
              Um agente entrará em contato com você em breve.
            </p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={() => navigate('/')}
                className="px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
              >
                Voltar ao Início
              </button>
              <button
                onClick={() => navigate('/affiliate/login')}
                className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors"
              >
                Acessar Painel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900 flex flex-col justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Logo size="lg" className="mx-auto mb-6" />
        <h2 className="text-center text-3xl font-extrabold text-gray-900 dark:text-white">
          Trabalhe Conosco
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600 dark:text-gray-400">
          Torne-se um afiliado e comece a ganhar
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white dark:bg-gray-800 py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {error && (
            <div className="mb-4 p-3 bg-red-50 dark:bg-red-900/50 text-red-600 dark:text-red-200 rounded-md text-sm">
              {error}
            </div>
          )}

          <form className="space-y-6" onSubmit={handleSubmit}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Email Profissional *
              </label>
              <input
                type="email"
                id="email"
                value={formData.email}
                onChange={(e) => setFormData((prev) => ({ ...prev, email: e.target.value }))}
                className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-purple-500 focus:ring-purple-500 dark:bg-gray-700 dark:text-white"
                required
              />
            </div>

            <div>
              <label htmlFor="whatsapp" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                WhatsApp *
              </label>
              <input
                type="tel"
                id="whatsapp"
                value={formData.whatsapp}
                onChange={(e) => setFormData((prev) => ({ ...prev, whatsapp: e.target.value }))}
                className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-purple-500 focus:ring-purple-500 dark:bg-gray-700 dark:text-white"
                required
              />
            </div>

            <div>
              <label htmlFor="referralCode" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Código de Referência (Opcional)
              </label>
              <input
                type="text"
                id="referralCode"
                value={formData.referralCode || ''}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    referralCode: formData.referralCode ? formData.referralCode : e.target.value
                  }))
                }
                className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-purple-500 focus:ring-purple-500 dark:bg-gray-700 dark:text-white"
                readOnly={!!formData.referralCode}
              />
            </div>

            <div className="relative">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Senha *
              </label>
              <div className="mt-1 relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="password"
                  value={formData.password}
                  onChange={(e) => setFormData((prev) => ({ ...prev, password: e.target.value }))}
                  className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-purple-500 focus:ring-purple-500 dark:bg-gray-700 dark:text-white"
                  required
                  minLength={6}
                />
              </div>
            </div>

            <div className="relative">
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Confirmar Senha *
              </label>
              <div className="mt-1 relative">
                <input
                  type={showPassword ? 'text' : 'password'}
                  id="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={(e) => setFormData((prev) => ({ ...prev, confirmPassword: e.target.value }))}
                  className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-purple-500 focus:ring-purple-500 dark:bg-gray-700 dark:text-white"
                  required
                />
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Redes Sociais (Opcional)
              </label>
              <div className="flex flex-wrap gap-2">
                <SocialNetworkButton network="instagram" onClick={() => handleSocialNetworkAdd('instagram')} />
                <SocialNetworkButton network="facebook" onClick={() => handleSocialNetworkAdd('facebook')} />
                <SocialNetworkButton network="tiktok" onClick={() => handleSocialNetworkAdd('tiktok')} />
                <SocialNetworkButton network="youtube" onClick={() => handleSocialNetworkAdd('youtube')} />
                <SocialNetworkButton network="twitter" onClick={() => handleSocialNetworkAdd('twitter')} />
                <SocialNetworkButton network="telegram" onClick={() => handleSocialNetworkAdd('telegram')} />
              </div>

              {formData.socialNetworks.map((network, index) => (
                <div key={index} className="mt-2">
                  <input
                    type="url"
                    placeholder={`Link do seu perfil no ${network.type}`}
                    value={network.link}
                    onChange={(e) => handleSocialNetworkChange(index, e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 dark:border-gray-600 shadow-sm focus:border-purple-500 focus:ring-purple-500 dark:bg-gray-700 dark:text-white"
                  />
                </div>
              ))}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">
                Resultados Anteriores (Opcional)
              </label>
              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-md">
                <div className="space-y-1 text-center">
                  <Upload className="mx-auto h-12 w-12 text-gray-400" />
                  <div className="flex text-sm text-gray-600 dark:text-gray-400">
                    <label htmlFor="results" className="relative cursor-pointer rounded-md font-medium text-purple-600 hover:text-purple-500">
                      <span>Upload de arquivos</span>
                      <input
                        id="results"
                        type="file"
                        multiple
                        accept="image/*,.pdf"
                        className="sr-only"
                        onChange={handleFileChange}
                      />
                    </label>
                  </div>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    PNG, JPG, PDF até 10MB
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col space-y-4">
              <button
                type="submit"
                disabled={loading}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {loading ? 'Enviando...' : 'Enviar Cadastro'}
              </button>

              <button
                type="button"
                onClick={() => navigate('/affiliate/login')}
                className="w-full flex items-center justify-center px-4 py-2 bg-purple-100 dark:bg-purple-900/20 text-purple-600 dark:text-purple-400 rounded-lg hover:bg-purple-200 dark:hover:bg-purple-900/40 transition-colors"
              >
                <Briefcase className="h-5 w-5 mr-2" />
                Acessar Painel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CareersForm;
