import React, { createContext, useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from './AuthContext';
import { getUserData } from '../services/user';

interface UserData {
  name: string;
  email: string;
  cpf: string;
  phone: string;
  createdAt: string;
}

interface UserContextType {
  userData: UserData | null;
  loading: boolean;
  isAdmin: boolean;
}

const UserContext = createContext<UserContextType | null>(null);

const ADMIN_EMAILS = [
  'protegido020@outlook.com',
  'metwaycompanypagamentos@gmail.com',
].map(email => email.toLowerCase());

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userData, setUserData] = useState<UserData | null>(null);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const { userId } = useParams();

  const isAdmin = userData?.email && ADMIN_EMAILS.includes(userData.email.toLowerCase());

  useEffect(() => {
    const fetchUserData = async () => {
      if (!currentUser && !userId) {
        console.warn('Nenhum usuário encontrado.');
        setUserData(null);
        setLoading(false);
        return;
      }

      try {
        const targetUserId = userId && isAdmin ? userId : currentUser?.uid;
        console.log('Fetching user data for ID:', targetUserId);
        const data = await getUserData(targetUserId!);
        setUserData(data);
      } catch (error) {
        console.error('Erro ao buscar dados do usuário:', error);
        setUserData(null);
      }
      setLoading(false);
    };

    fetchUserData();
  }, [currentUser, userId, isAdmin]);

  return (
    <UserContext.Provider value={{ userData, loading, isAdmin }}>
      {!loading && children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
