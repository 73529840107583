export default {
  translation: {
    common: {
      welcome: 'Bienvenido',
      loading: 'Cargando...',
      error: 'Error',
      success: 'Éxito',
      save: 'Guardar',
      cancel: 'Cancelar',
      search: 'Buscar',
      noResults: 'No se encontraron resultados',
      menu: 'Menú'
    },
    auth: {
      login: 'Iniciar sesión',
      register: 'Registrarse',
      logout: 'Cerrar sesión',
      email: 'Correo electrónico',
      password: 'Contraseña',
      confirmPassword: 'Confirmar contraseña',
      forgotPassword: '¿Olvidaste tu contraseña?',
      name: 'Nombre completo',
      phone: 'Teléfono',
      country: 'País',
      birthDate: 'Fecha de Nacimiento',
      motherName: 'Nombre de la Madre'
    },
    dashboard: {
      overview: 'Vista General',
      investments: 'Inversiones',
      transactions: 'Transacciones',
      settings: 'Configuración',
      security: 'Seguridad',
      support: 'Soporte',
      profile: 'Perfil',
      totalInvestment: 'Inversión Total',
      availableBalance: 'Saldo Disponible',
      pendingTransactions: 'Transacciones Pendientes'
    },
    admin: {
      users: 'Usuarios',
      approvals: 'Aprobaciones',
      withdrawals: 'Retiros',
      reports: 'Informes',
      metrics: {
        revenue: 'Ingresos Totales',
        investments: 'Total Invertido',
        contracts: 'Contratos de Crédito',
        commissions: 'Comisiones de Afiliados'
      }
    },
    affiliate: {
      dashboard: 'Panel de Afiliado',
      network: 'Mi Red',
      earnings: 'Ganancias',
      referrals: 'Referencias',
      marketing: 'Marketing',
      commission: 'Comisión',
      metrics: {
        totalReferrals: 'Referencias Totales',
        activeReferrals: 'Referencias Activas',
        earnings: 'Ganancias Totales',
        clickCount: 'Clics en el Enlace'
      }
    }
  }
};