import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { useUser } from '../../contexts/UserContext';
import {
  LayoutDashboard,
  User,
  History,
  Settings,
  Shield,
  LogOut,
  X,
  CreditCard,
  Sparkles,
  UserCog
} from 'lucide-react';

interface SidebarProps {
  onClose?: () => void;
}

const Sidebar = ({ onClose }: SidebarProps) => {
  const { logout } = useAuth();
  const { userData, isAdmin } = useUser();
  const navigate = useNavigate();
  const firstName = userData?.name.split(' ')[0] || '';

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <div className="w-64 bg-purple-600 text-white h-full flex flex-col">
      <div className="p-4">
        <div className="flex items-center justify-between mb-8">
          <div className="flex items-center space-x-3">
            <img
              src="https://github.com/identicons/user.png"
              alt="Profile"
              className="w-8 sm:w-10 h-8 sm:h-10 rounded-full"
            />
            <div>
              <h2 className="font-semibold text-sm sm:text-base">{firstName}</h2>
              <p className="text-xs sm:text-sm text-purple-200">Premium</p>
            </div>
          </div>
          {onClose && (
            <button 
              onClick={onClose}
              className="lg:hidden p-2 hover:bg-purple-700 rounded-lg"
            >
              <X className="h-5 w-5" />
            </button>
          )}
        </div>

        <nav className="space-y-1">
          <SidebarLink to="/dashboard" icon={LayoutDashboard} text="Dashboard" />
          <SidebarLink to="/account" icon={User} text="Conta" />
          <SidebarLink to="/transactions" icon={History} text="Transações" />
          <SidebarLink to="/settings" icon={Settings} text="Configurações" />
          <SidebarLink to="/security" icon={Shield} text="Segurança" />
          
          {/* Admin Button */}
          {isAdmin && (
            <button
              onClick={() => navigate('/admin')}
              className="w-full flex items-center space-x-3 px-4 py-2 rounded-lg text-purple-200 hover:bg-purple-700 hover:text-white transition-colors"
            >
              <UserCog className="h-5 w-5" />
              <span>Admin</span>
            </button>
          )}
          
          {/* Credit Button with Glow Effect */}
          <button
            onClick={() => navigate('/credit')}
            className="w-full mt-4 relative group"
          >
            <div className="absolute -inset-0.5 bg-gradient-to-r from-purple-400 to-purple-300 rounded-lg blur opacity-50 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-pulse"></div>
            <div className="relative flex items-center space-x-2 px-4 py-3 bg-purple-700 rounded-lg leading-none">
              <CreditCard className="h-5 w-5 text-purple-200" />
              <span className="text-purple-100">Crédito</span>
              <Sparkles className="h-4 w-4 text-yellow-300 animate-pulse" />
            </div>
          </button>
        </nav>
      </div>

      <div className="mt-auto p-4">
        <button 
          onClick={handleLogout}
          className="flex items-center space-x-2 text-purple-200 hover:text-white transition-colors w-full text-sm sm:text-base"
        >
          <LogOut className="h-4 sm:h-5 w-4 sm:w-5" />
          <span>Sair</span>
        </button>
      </div>

      <div className="p-4 border-t border-purple-500">
        <div className="flex items-center justify-center space-x-2">
          <svg className="h-6 sm:h-8 w-6 sm:w-8 text-white" viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"
            />
          </svg>
          <span className="text-xs sm:text-sm text-purple-200">© 2023 HK Bank</span>
        </div>
      </div>
    </div>
  );
};

interface SidebarLinkProps {
  to: string;
  icon: React.ElementType;
  text: string;
}

const SidebarLink = ({ to, icon: Icon, text }: SidebarLinkProps) => {
  const navigate = useNavigate();
  const isActive = window.location.pathname === to;

  return (
    <button
      onClick={() => navigate(to)}
      className={`flex items-center space-x-3 px-4 py-2 rounded-lg transition-colors text-sm sm:text-base w-full text-left ${
        isActive 
          ? 'bg-purple-700 text-white' 
          : 'text-purple-200 hover:bg-purple-700 hover:text-white'
      }`}
    >
      <Icon className="h-4 sm:h-5 w-4 sm:w-5" />
      <span>{text}</span>
    </button>
  );
};

export default Sidebar;