import { z } from 'zod';

export const registerSchema = z.object({
  name: z.string().min(3, 'Nome deve ter pelo menos 3 caracteres'),
  country: z.string(),
  cpf: z.string().optional(),
  birthDate: z.string().optional(),
  motherName: z.string().optional(),
  email: z.string().email('Email inválido'),
  phone: z.string().regex(/^\(\d{2}\) \d{5}-\d{4}$/, 'Telefone inválido'),
  password: z.string().min(8, 'Senha deve ter pelo menos 8 caracteres'),
  confirmPassword: z.string()
}).refine((data) => {
  // Only validate CPF, birthDate and motherName if country is Brazil
  if (data.country === 'BR') {
    if (!data.cpf || !data.cpf.match(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/)) {
      return false;
    }
    if (!data.birthDate) {
      return false;
    }
    if (!data.motherName || data.motherName.length < 3) {
      return false;
    }
  }
  return data.password === data.confirmPassword;
}, {
  message: "Senhas não conferem ou campos obrigatórios não preenchidos",
  path: ["confirmPassword"],
});

export const loginSchema = z.object({
  email: z.string().email('Email inválido'),
  password: z.string().min(8, 'Senha deve ter pelo menos 8 caracteres'),
});