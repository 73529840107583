import React from 'react';
import { User, Calendar, DollarSign, CreditCard, Mail, Phone } from 'lucide-react';

interface UserInfoProps {
  chatId: string | null;
}

const UserInfo: React.FC<UserInfoProps> = ({ chatId }) => {
  if (!chatId) {
    return null;
  }

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm">
      <div className="p-4 border-b border-gray-200 dark:border-gray-700">
        <div className="flex items-center space-x-3">
          <div className="w-12 h-12 rounded-full bg-gray-200 dark:bg-gray-700 flex items-center justify-center">
            <User className="h-6 w-6 text-gray-600 dark:text-gray-400" />
          </div>
          <div>
            <h3 className="text-lg font-medium text-gray-900 dark:text-white">
              João Silva
            </h3>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Cliente desde Set 2023
            </p>
          </div>
        </div>
      </div>

      <div className="p-4 space-y-4">
        <div>
          <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Informações de Contato
          </h4>
          <div className="space-y-2">
            <div className="flex items-center text-sm">
              <Mail className="h-4 w-4 text-gray-400 mr-2" />
              <span className="text-gray-600 dark:text-gray-400">
                joao.silva@email.com
              </span>
            </div>
            <div className="flex items-center text-sm">
              <Phone className="h-4 w-4 text-gray-400 mr-2" />
              <span className="text-gray-600 dark:text-gray-400">
                (11) 98765-4321
              </span>
            </div>
          </div>
        </div>

        <div>
          <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Investimentos
          </h4>
          <div className="space-y-2">
            <div className="flex items-center justify-between text-sm">
              <div className="flex items-center">
                <DollarSign className="h-4 w-4 text-gray-400 mr-2" />
                <span className="text-gray-600 dark:text-gray-400">
                  Total Investido
                </span>
              </div>
              <span className="font-medium text-gray-900 dark:text-white">
                R$ 10.000,00
              </span>
            </div>
            <div className="flex items-center justify-between text-sm">
              <div className="flex items-center">
                <Calendar className="h-4 w-4 text-gray-400 mr-2" />
                <span className="text-gray-600 dark:text-gray-400">
                  Último Investimento
                </span>
              </div>
              <span className="font-medium text-gray-900 dark:text-white">
                15/03/2024
              </span>
            </div>
          </div>
        </div>

        <div>
          <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Plano de Crédito
          </h4>
          <div className="flex items-center justify-between text-sm">
            <div className="flex items-center">
              <CreditCard className="h-4 w-4 text-gray-400 mr-2" />
              <span className="text-gray-600 dark:text-gray-400">
                Status
              </span>
            </div>
            <span className="px-2 py-1 text-xs font-medium bg-green-100 text-green-800 dark:bg-green-900/20 dark:text-green-400 rounded-full">
              Ativo
            </span>
          </div>
        </div>

        <div className="pt-4 border-t border-gray-200 dark:border-gray-700">
          <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
            Histórico de Atendimentos
          </h4>
          <div className="space-y-2">
            <div className="text-sm">
              <p className="text-gray-600 dark:text-gray-400">
                Último atendimento: 2 dias atrás
              </p>
              <p className="text-gray-600 dark:text-gray-400">
                Total de atendimentos: 3
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;