export default {
  translation: {
    common: {
      welcome: 'Bem-vindo',
      loading: 'Carregando...',
      error: 'Erro',
      success: 'Sucesso',
      save: 'Salvar',
      cancel: 'Cancelar',
      search: 'Buscar',
      noResults: 'Nenhum resultado encontrado',
      menu: 'Menu'
    },
    auth: {
      login: 'Entrar',
      register: 'Cadastrar',
      logout: 'Sair',
      email: 'Email',
      password: 'Senha',
      confirmPassword: 'Confirmar senha',
      forgotPassword: 'Esqueceu a senha?',
      name: 'Nome completo',
      phone: 'Telefone',
      country: 'País',
      birthDate: 'Data de Nascimento',
      motherName: 'Nome da Mãe'
    },
    dashboard: {
      overview: 'Visão Geral',
      investments: 'Investimentos',
      transactions: 'Transações',
      settings: 'Configurações',
      security: 'Segurança',
      support: 'Suporte',
      profile: 'Perfil',
      totalInvestment: 'Total Investido',
      availableBalance: 'Saldo Disponível',
      pendingTransactions: 'Transações Pendentes'
    },
    admin: {
      users: 'Usuários',
      approvals: 'Aprovações',
      withdrawals: 'Saques',
      reports: 'Relatórios',
      metrics: {
        revenue: 'Faturamento Total',
        investments: 'Total Investido',
        contracts: 'Contratos de Crédito',
        commissions: 'Comissões de Afiliados'
      }
    },
    affiliate: {
      dashboard: 'Painel do Afiliado',
      network: 'Minha Rede',
      earnings: 'Ganhos',
      referrals: 'Indicações',
      marketing: 'Marketing',
      commission: 'Comissão',
      metrics: {
        totalReferrals: 'Total de Indicações',
        activeReferrals: 'Indicações Ativas',
        earnings: 'Ganhos Totais',
        clickCount: 'Cliques no Link'
      }
    }
  }
};