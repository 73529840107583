import React from 'react';
import { Sun, TrendingUp, Leaf, DollarSign } from 'lucide-react';

const SolarMarketStats = () => {
  return (
    <section className="py-20 bg-gradient-to-b from-purple-50 to-white dark:from-gray-800 dark:to-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white sm:text-4xl">
            O Mercado de Energia Solar no Brasil
          </h2>
          <p className="mt-4 text-lg text-gray-600 dark:text-gray-400">
            Invista no futuro da energia limpa e sustentável
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Capacity Stat */}
          <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg transform hover:scale-105 transition-transform duration-300">
            <div className="flex items-center justify-center w-12 h-12 bg-yellow-100 dark:bg-yellow-900/50 rounded-full mb-4">
              <Sun className="h-6 w-6 text-yellow-600 dark:text-yellow-400" />
            </div>
            <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">34 GW</h3>
            <p className="text-gray-600 dark:text-gray-400">
              Capacidade instalada em 2023, tornando-se a segunda maior fonte de energia no Brasil
            </p>
          </div>

          {/* Growth Stat */}
          <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg transform hover:scale-105 transition-transform duration-300">
            <div className="flex items-center justify-center w-12 h-12 bg-green-100 dark:bg-green-900/50 rounded-full mb-4">
              <TrendingUp className="h-6 w-6 text-green-600 dark:text-green-400" />
            </div>
            <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">15%</h3>
            <p className="text-gray-600 dark:text-gray-400">
              Redução prevista no custo dos painéis solares em 2024
            </p>
          </div>

          {/* Environmental Impact */}
          <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg transform hover:scale-105 transition-transform duration-300">
            <div className="flex items-center justify-center w-12 h-12 bg-blue-100 dark:bg-blue-900/50 rounded-full mb-4">
              <Leaf className="h-6 w-6 text-blue-600 dark:text-blue-400" />
            </div>
            <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">3%</h3>
            <p className="text-gray-600 dark:text-gray-400">
              Redução nas emissões globais de CO₂ através da energia solar
            </p>
          </div>

          {/* Revenue Potential */}
          <div className="bg-white dark:bg-gray-800 rounded-xl p-6 shadow-lg transform hover:scale-105 transition-transform duration-300">
            <div className="flex items-center justify-center w-12 h-12 bg-purple-100 dark:bg-purple-900/50 rounded-full mb-4">
              <DollarSign className="h-6 w-6 text-purple-600 dark:text-purple-400" />
            </div>
            <h3 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">R$ 100B</h3>
            <p className="text-gray-600 dark:text-gray-400">
              Faturamento projetado do setor até 2030
            </p>
          </div>
        </div>

        {/* Market Details */}
        <div className="mt-20 grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div className="bg-white dark:bg-gray-800 rounded-xl p-8 shadow-lg">
            <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-4">
              Expansão no Mercado Brasileiro
            </h3>
            <p className="text-gray-600 dark:text-gray-400 mb-4">
              O Brasil atingiu mais de 34 GW de capacidade instalada em outubro de 2023, consolidando a energia solar como a segunda maior fonte de energia na matriz energética nacional.
            </p>
            <img
              src="https://images.unsplash.com/photo-1509391366360-2e959784a276?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
              alt="Painéis Solares no Brasil"
              className="w-full h-48 object-cover rounded-lg"
            />
          </div>

          <div className="bg-white dark:bg-gray-800 rounded-xl p-8 shadow-lg">
            <h3 className="text-xl font-bold text-gray-900 dark:text-white mb-4">
              Inovação e Sustentabilidade
            </h3>
            <p className="text-gray-600 dark:text-gray-400 mb-4">
              Avanços tecnológicos como monitoramento online e maior capacidade de armazenamento tornam a energia solar ainda mais eficiente e acessível, permitindo otimização do desempenho mesmo em dias com pouca luz solar.
            </p>
            <img
              src="https://images.unsplash.com/photo-1508514177221-188b1cf16e9d?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
              alt="Tecnologia Solar"
              className="w-full h-48 object-cover rounded-lg"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SolarMarketStats;