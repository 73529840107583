import React, { useState, useEffect } from 'react';
import { History, ArrowUpRight, ArrowDownLeft, Clock, Filter } from 'lucide-react';
import DashboardLayout from '../dashboard/DashboardLayout';
import { useAuth } from '../../contexts/AuthContext';
import { getUserTransactions, type Transaction } from '../../services/transactions';
import { formatCurrency } from '../../utils/format';

type TransactionType = 'all' | 'deposit' | 'withdrawal' | 'investment' | 'profit';

const TransactionsContent = () => {
  const { currentUser } = useAuth();
  const [selectedType, setSelectedType] = useState<TransactionType>('all');
  const [dateRange, setDateRange] = useState('all');
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchTransactions = async () => {
      if (!currentUser) return;
      
      try {
        setLoading(true);
        const userTransactions = await getUserTransactions(currentUser.uid);
        setTransactions(userTransactions);
      } catch (error) {
        console.error('Error fetching transactions:', error);
        setError('Erro ao carregar transações');
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [currentUser]);

  const filteredTransactions = transactions.filter(transaction => {
    if (selectedType !== 'all' && transaction.type !== selectedType) {
      return false;
    }
    // Add date range filtering logic here if needed
    return true;
  });

  const getTransactionIcon = (type: string) => {
    switch (type) {
      case 'deposit':
        return <ArrowDownLeft className="h-5 w-5 text-green-500" />;
      case 'withdrawal':
        return <ArrowUpRight className="h-5 w-5 text-red-500" />;
      case 'investment':
        return <History className="h-5 w-5 text-purple-500" />;
      case 'profit':
        return <Clock className="h-5 w-5 text-blue-500" />;
      default:
        return <History className="h-5 w-5 text-gray-500" />;
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'completed':
        return 'bg-green-100 text-green-800 dark:bg-green-900/50 dark:text-green-400';
      case 'pending':
        return 'bg-yellow-100 text-yellow-800 dark:bg-yellow-900/50 dark:text-yellow-400';
      case 'cancelled':
        return 'bg-red-100 text-red-800 dark:bg-red-900/50 dark:text-red-400';
      default:
        return 'bg-gray-100 text-gray-800 dark:bg-gray-900/50 dark:text-gray-400';
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto px-4 py-8">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Histórico de Transações</h1>
        <p className="mt-2 text-gray-600 dark:text-gray-400">
          Visualize todas as suas movimentações financeiras
        </p>
      </div>

      {/* Filters */}
      <div className="mb-6 flex flex-col sm:flex-row gap-4">
        <div className="flex-1">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Tipo de Transação
          </label>
          <select
            value={selectedType}
            onChange={(e) => setSelectedType(e.target.value as TransactionType)}
            className="w-full rounded-lg border-gray-300 dark:border-gray-600 shadow-sm focus:border-purple-500 focus:ring-purple-500 dark:bg-gray-700 dark:text-white"
          >
            <option value="all">Todas</option>
            <option value="deposit">Depósitos</option>
            <option value="withdrawal">Saques</option>
            <option value="investment">Investimentos</option>
            <option value="profit">Rendimentos</option>
          </select>
        </div>
        <div className="flex-1">
          <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
            Período
          </label>
          <select
            value={dateRange}
            onChange={(e) => setDateRange(e.target.value)}
            className="w-full rounded-lg border-gray-300 dark:border-gray-600 shadow-sm focus:border-purple-500 focus:ring-purple-500 dark:bg-gray-700 dark:text-white"
          >
            <option value="all">Todo período</option>
            <option value="today">Hoje</option>
            <option value="week">Última semana</option>
            <option value="month">Último mês</option>
            <option value="year">Último ano</option>
          </select>
        </div>
      </div>

      {/* Transactions List */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm overflow-hidden">
        {error ? (
          <div className="p-4 text-center text-red-600 dark:text-red-400">
            {error}
          </div>
        ) : filteredTransactions.length === 0 ? (
          <div className="p-4 text-center text-gray-500 dark:text-gray-400">
            Nenhuma transação encontrada
          </div>
        ) : (
          <div className="divide-y divide-gray-200 dark:divide-gray-700">
            {filteredTransactions.map((transaction) => (
              <div key={transaction.id} className="p-4 hover:bg-gray-50 dark:hover:bg-gray-700/50">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-4">
                    <div className="w-10 h-10 rounded-lg bg-gray-100 dark:bg-gray-700 flex items-center justify-center">
                      {getTransactionIcon(transaction.type)}
                    </div>
                    <div>
                      <p className="font-medium text-gray-900 dark:text-white">
                        {transaction.description}
                      </p>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        {new Date(transaction.createdAt).toLocaleString('pt-BR')}
                      </p>
                    </div>
                  </div>
                  <div className="text-right">
                    <p className={`font-medium ${
                      transaction.type === 'withdrawal' ? 'text-red-600 dark:text-red-400' :
                      transaction.type === 'profit' || transaction.type === 'deposit' ? 'text-green-600 dark:text-green-400' :
                      'text-gray-900 dark:text-white'
                    }`}>
                      {transaction.type === 'withdrawal' ? '-' : '+'}
                      {formatCurrency(transaction.amount)}
                    </p>
                    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                      getStatusColor(transaction.status)
                    }`}>
                      {transaction.status === 'completed' ? 'Concluído' :
                       transaction.status === 'pending' ? 'Pendente' : 'Cancelado'}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

const TransactionsPage = () => {
  return (
    <DashboardLayout>
      <TransactionsContent />
    </DashboardLayout>
  );
};

export default TransactionsPage;