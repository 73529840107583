import { 
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential
} from 'firebase/auth';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { auth, db } from '../config/firebase';

// 2FA Functions
export const enable2FA = async (userId: string) => {
  try {
    // First check if user document exists
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) {
      await updateDoc(userRef, {
        has2FA: false,
        twoFactorSecret: null,
        updatedAt: new Date().toISOString()
      });
    }

    // Generate a secret key (in production, use a proper TOTP library)
    const secretKey = 'EXAMPLE2FASECRET';
    const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?data=otpauth://totp/HKBank:${userDoc.data()?.email}?secret=${secretKey}`;
    
    await updateDoc(userRef, {
      has2FA: true,
      twoFactorSecret: secretKey,
      updatedAt: new Date().toISOString()
    });

    return { qrCodeUrl, secretKey };
  } catch (error) {
    console.error('Error enabling 2FA:', error);
    throw error;
  }
};

export const verify2FA = async (userId: string, code: string): Promise<boolean> => {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) {
      return false;
    }

    // In production, implement proper TOTP verification
    return code.length === 6;
  } catch (error) {
    console.error('Error verifying 2FA:', error);
    return false;
  }
};

// PIN Functions
export const setupPIN = async (userId: string, pin: string) => {
  try {
    // First check if user document exists
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) {
      await updateDoc(userRef, {
        hasPin: false,
        pin: null,
        updatedAt: new Date().toISOString()
      });
    }

    // Hash the PIN before storing (in production, use a proper hashing algorithm)
    const hashedPin = btoa(pin); // Simple base64 encoding for demo
    
    await updateDoc(userRef, {
      hasPin: true,
      pin: hashedPin,
      updatedAt: new Date().toISOString()
    });

    return true;
  } catch (error) {
    console.error('Error setting up PIN:', error);
    throw error;
  }
};

export const verifyPIN = async (userId: string, pin: string): Promise<boolean> => {
  try {
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    
    if (!userDoc.exists()) {
      return false;
    }

    const userData = userDoc.data();
    const hashedPin = btoa(pin);
    
    return userData.pin === hashedPin;
  } catch (error) {
    console.error('Error verifying PIN:', error);
    return false;
  }
};

// Password Change
export const changePassword = async (email: string, currentPassword: string, newPassword: string) => {
  const user = auth.currentUser;
  if (!user) throw new Error('No user logged in');

  const credential = EmailAuthProvider.credential(email, currentPassword);
  await reauthenticateWithCredential(user, credential);
  await updatePassword(user, newPassword);
};