import React, { useState } from 'react';
import { Camera, Mail, CreditCard, Phone, Copy, Check } from 'lucide-react';
import { useUser } from '../../contexts/UserContext';
import { updateUserProfile, updatePixKey } from '../../services/user';
import Logo from '../Logo';
import DashboardLayout from '../dashboard/DashboardLayout';

const AccountContent = () => {
  const { userData } = useUser();
  const [selectedPixType, setSelectedPixType] = useState<'email' | 'cpf' | 'phone' | 'random'>('email');
  const [pixKey, setPixKey] = useState('');
  const [copied, setCopied] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handlePixKeyGeneration = async () => {
    try {
      setLoading(true);
      let generatedKey = '';
      
      switch (selectedPixType) {
        case 'email':
          generatedKey = userData?.email || '';
          break;
        case 'cpf':
          generatedKey = userData?.cpf || '';
          break;
        case 'phone':
          generatedKey = userData?.phone || '';
          break;
        case 'random':
          generatedKey = Math.random().toString(36).substring(2, 15) + 
                        Math.random().toString(36).substring(2, 15);
          break;
      }

      await updatePixKey(generatedKey, selectedPixType);
      setPixKey(generatedKey);
      setMessage('Chave PIX gerada com sucesso!');
    } catch (error) {
      setMessage('Erro ao gerar chave PIX. Tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(pixKey);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error('Failed to copy:', error);
    }
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="mb-8 flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Minha Conta</h1>
        <Logo size="sm" />
      </div>

      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm overflow-hidden">
        {/* Profile Section */}
        <div className="p-6 border-b border-gray-200 dark:border-gray-700">
          <div className="flex items-center space-x-4">
            <div className="relative">
              <div className="w-20 h-20 rounded-full bg-purple-100 dark:bg-purple-900/50 flex items-center justify-center">
                <Camera className="w-8 h-8 text-purple-600 dark:text-purple-400" />
              </div>
              <button className="absolute bottom-0 right-0 bg-purple-600 text-white p-1 rounded-full hover:bg-purple-700 transition-colors">
                <Camera className="w-4 h-4" />
              </button>
            </div>
            <div>
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white">{userData?.name}</h2>
              <p className="text-gray-500 dark:text-gray-400">Premium</p>
            </div>
          </div>
        </div>

        {/* Personal Information */}
        <div className="p-6 border-b border-gray-200 dark:border-gray-700">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Informações Pessoais</h3>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Email</label>
              <div className="mt-1 flex items-center space-x-2">
                <Mail className="w-5 h-5 text-gray-400" />
                <span className="text-gray-900 dark:text-white">{userData?.email}</span>
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">CPF</label>
              <div className="mt-1 flex items-center space-x-2">
                <CreditCard className="w-5 h-5 text-gray-400" />
                <span className="text-gray-900 dark:text-white">{userData?.cpf}</span>
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Telefone</label>
              <div className="mt-1 flex items-center space-x-2">
                <Phone className="w-5 h-5 text-gray-400" />
                <span className="text-gray-900 dark:text-white">{userData?.phone}</span>
              </div>
            </div>
          </div>
        </div>

        {/* PIX Keys */}
        <div className="p-6">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">Chaves PIX</h3>
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Tipo de Chave
              </label>
              <div className="flex flex-wrap gap-4">
                <button
                  onClick={() => setSelectedPixType('email')}
                  className={`px-4 py-2 rounded-lg flex items-center gap-2 ${
                    selectedPixType === 'email'
                      ? 'bg-purple-600 text-white'
                      : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
                  }`}
                >
                  <Mail className="w-4 h-4" />
                  Email
                </button>
                <button
                  onClick={() => setSelectedPixType('cpf')}
                  className={`px-4 py-2 rounded-lg flex items-center gap-2 ${
                    selectedPixType === 'cpf'
                      ? 'bg-purple-600 text-white'
                      : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
                  }`}
                >
                  <CreditCard className="w-4 h-4" />
                  CPF
                </button>
                <button
                  onClick={() => setSelectedPixType('phone')}
                  className={`px-4 py-2 rounded-lg flex items-center gap-2 ${
                    selectedPixType === 'phone'
                      ? 'bg-purple-600 text-white'
                      : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
                  }`}
                >
                  <Phone className="w-4 h-4" />
                  Telefone
                </button>
                <button
                  onClick={() => setSelectedPixType('random')}
                  className={`px-4 py-2 rounded-lg ${
                    selectedPixType === 'random'
                      ? 'bg-purple-600 text-white'
                      : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
                  }`}
                >
                  Aleatória
                </button>
              </div>
            </div>

            <button
              onClick={handlePixKeyGeneration}
              disabled={loading}
              className="w-full bg-purple-600 text-white px-4 py-2 rounded-lg hover:bg-purple-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loading ? 'Gerando...' : 'Gerar Chave PIX'}
            </button>

            {pixKey && (
              <div className="mt-4 p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
                <div className="flex items-center justify-between">
                  <span className="text-gray-900 dark:text-white font-medium">{pixKey}</span>
                  <button
                    onClick={copyToClipboard}
                    className="text-purple-600 hover:text-purple-700 dark:text-purple-400"
                  >
                    {copied ? <Check className="w-5 h-5" /> : <Copy className="w-5 h-5" />}
                  </button>
                </div>
              </div>
            )}

            {message && (
              <div className={`mt-4 p-4 rounded-lg ${
                message.includes('sucesso')
                  ? 'bg-green-50 dark:bg-green-900/50 text-green-800 dark:text-green-200'
                  : 'bg-red-50 dark:bg-red-900/50 text-red-800 dark:text-red-200'
              }`}>
                {message}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const AccountPage = () => {
  return (
    <DashboardLayout>
      <AccountContent />
    </DashboardLayout>
  );
};

export default AccountPage;