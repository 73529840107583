import React, { useState } from 'react';
import { Copy, Check, X } from 'lucide-react';

interface PaymentModalProps {
  amount: number;
  onClose: () => void;
  onSuccess: () => void;
  qrCode: string; // Adiciona o QR Code como propriedade
}

const PaymentModal: React.FC<PaymentModalProps> = ({ amount, onClose, onSuccess, qrCode }) => {
  const [copied, setCopied] = useState(false);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(qrCode);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error('Failed to copy:', error);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-md w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
            Pagamento via PIX
          </h3>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="space-y-4">
          <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
            <p className="text-sm text-gray-600 dark:text-gray-400 mb-2">
              Valor a pagar
            </p>
            <p className="text-2xl font-bold text-gray-900 dark:text-white">
              {amount.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              })}
            </p>
          </div>

          <div>
            <p className="text-sm text-gray-600 dark:text-gray-400 mb-2">
              QR Code PIX
            </p>
            <div className="bg-white p-4 rounded-lg flex justify-center">
              <img
                src={`https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${encodeURIComponent(
                  qrCode
                )}`}
                alt="QR Code PIX"
                className="w-48 h-48"
              />
            </div>
          </div>

          <div>
            <p className="text-sm text-gray-600 dark:text-gray-400 mb-2">
              Código do QR Code
            </p>
            <div className="flex items-center justify-between p-2 bg-gray-50 dark:bg-gray-700 rounded">
              <code className="text-sm text-gray-800 dark:text-gray-200 break-all">
                {qrCode}
              </code>
              <button
                onClick={copyToClipboard}
                className="text-purple-600 hover:text-purple-700 dark:text-purple-400"
              >
                {copied ? <Check className="h-5 w-5" /> : <Copy className="h-5 w-5" />}
              </button>
            </div>
          </div>

          <div className="text-sm text-gray-600 dark:text-gray-400">
            <p>Instruções:</p>
            <ol className="list-decimal ml-4 space-y-1">
              <li>Abra seu aplicativo bancário</li>
              <li>Escaneie o QR Code ou copie o código</li>
              <li>Confirme o valor e o destinatário</li>
              <li>Conclua o pagamento</li>
            </ol>
          </div>

          <div className="flex justify-end space-x-3">
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
            >
              Cancelar
            </button>
            <button
              onClick={onSuccess}
              className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
            >
              Confirmar Pagamento
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentModal;
