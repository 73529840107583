import React from 'react';
import DashboardLayout from '../dashboard/DashboardLayout';
import CreditAnalysis from './CreditAnalysis';

const CreditAnalysisPage = () => {
  return (
    <DashboardLayout>
      <CreditAnalysis />
    </DashboardLayout>
  );
};

export default CreditAnalysisPage;