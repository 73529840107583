import React from 'react';
import { Menu, X, Briefcase } from 'lucide-react';
import { Link } from 'react-router-dom';
import ThemeToggle from './ThemeToggle';
import CurrencyTicker from './CurrencyTicker';
import LanguageSelector from './LanguageSelector';

const Navbar = () => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <nav className="fixed top-0 w-full bg-white/80 dark:bg-gray-900/80 backdrop-blur-md z-50 shadow-sm">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 items-center">
            <div>
              <Link to="/" className="text-xl font-bold text-purple-600 dark:text-purple-400">
                HK Bank
              </Link>
            </div>
            
            <div className="hidden md:flex items-center space-x-8">
              <a href="#features" className="text-gray-700 dark:text-gray-300 hover:text-purple-700 dark:hover:text-purple-400 transition-colors">Recursos</a>
              <a href="#about" className="text-gray-700 dark:text-gray-300 hover:text-purple-700 dark:hover:text-purple-400 transition-colors">Sobre</a>
              <a href="#testimonials" className="text-gray-700 dark:text-gray-300 hover:text-purple-700 dark:hover:text-purple-400 transition-colors">Depoimentos</a>
              <Link to="/login" className="text-gray-700 dark:text-gray-300 hover:text-purple-700 dark:hover:text-purple-400 transition-colors">Entrar</Link>
              <Link 
                to="/register" 
                className="bg-purple-600 text-white px-6 py-2 rounded-lg hover:bg-purple-700 transition-colors"
              >
                Abrir Conta
              </Link>
              <Link
                to="/careers"
                className="flex items-center space-x-2 text-purple-600 dark:text-purple-400 hover:text-purple-700 dark:hover:text-purple-500 transition-colors"
              >
                <Briefcase className="h-5 w-5" />
                <span>Trabalhe Conosco</span>
              </Link>
              <LanguageSelector />
              <ThemeToggle />
            </div>

            <div className="md:hidden flex items-center space-x-4">
              <LanguageSelector />
              <ThemeToggle />
              <button onClick={() => setIsOpen(!isOpen)} className="text-gray-700 dark:text-gray-300">
                {isOpen ? <X size={24} /> : <Menu size={24} />}
              </button>
            </div>
          </div>
        </div>

        {isOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3 bg-white dark:bg-gray-900">
              <a href="#features" className="block px-3 py-2 text-gray-700 dark:text-gray-300 hover:text-purple-700 dark:hover:text-purple-400">Recursos</a>
              <a href="#about" className="block px-3 py-2 text-gray-700 dark:text-gray-300 hover:text-purple-700 dark:hover:text-purple-400">Sobre</a>
              <a href="#testimonials" className="block px-3 py-2 text-gray-700 dark:text-gray-300 hover:text-purple-700 dark:hover:text-purple-400">Depoimentos</a>
              <Link to="/login" className="block px-3 py-2 text-gray-700 dark:text-gray-300 hover:text-purple-700 dark:hover:text-purple-400">Entrar</Link>
              <Link 
                to="/register" 
                className="block px-3 py-2 text-purple-600 dark:text-purple-400 font-medium hover:text-purple-700 dark:hover:text-purple-500"
              >
                Abrir Conta
              </Link>
              <Link
                to="/careers"
                className="block px-3 py-2 text-purple-600 dark:text-purple-400 font-medium hover:text-purple-700 dark:hover:text-purple-500"
              >
                <div className="flex items-center space-x-2">
                  <Briefcase className="h-5 w-5" />
                  <span>Trabalhe Conosco</span>
                </div>
              </Link>
            </div>
          </div>
        )}
      </nav>
      <div className="pt-16">
        <CurrencyTicker />
      </div>
    </>
  );
};

export default Navbar;