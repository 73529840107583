import { 
  collection,
  query,
  where,
  getDocs,
  addDoc,
  serverTimestamp,
  Timestamp,
  doc,
  getDoc
} from 'firebase/firestore';
import { db } from '../config/firebase';

export interface SolarPanelData {
  id: string;
  userId: string;
  investmentAmount: number;
  panelPercentage: number;
  energyProduction: number;
  co2Reduction: number;
  createdAt: string;
}

// Constants based on the new metrics (R$ 2.000,00 per panel)
const PANEL_COST = 2000; // Cost per panel in BRL
const BASE_ENERGY_PRODUCTION = 0.02; // kWh/month per R$ 1,00
const BASE_CO2_REDUCTION = 0.0017; // kg/month per R$ 1,00

export const calculateSolarMetrics = (investmentAmount: number): {
  panelPercentage: number;
  energyProduction: number;
  co2Reduction: number;
} => {
  // Calculate panel percentage (proportion)
  const panelPercentage = (investmentAmount / PANEL_COST) * 100;
  
  // Calculate energy production (kWh/month)
  // Using the ratio: R$ 1,00 = 0.02 kWh/month
  const energyProduction = investmentAmount * BASE_ENERGY_PRODUCTION;
  
  // Calculate CO2 reduction (kg/month)
  // Using the ratio: R$ 1,00 = 0.0017 kg CO2/month
  const co2Reduction = investmentAmount * BASE_CO2_REDUCTION;

  return {
    panelPercentage,
    energyProduction,
    co2Reduction
  };
};

export const createSolarPanelRecord = async (
  userId: string,
  investmentAmount: number
): Promise<string> => {
  try {
    const calculations = calculateSolarMetrics(investmentAmount);
    
    const docRef = await addDoc(collection(db, 'solarPanels'), {
      userId,
      investmentAmount,
      ...calculations,
      createdAt: serverTimestamp()
    });

    return docRef.id;
  } catch (error) {
    console.error('Error creating solar panel record:', error);
    throw error;
  }
};

export const getUserSolarPanels = async (userId: string): Promise<SolarPanelData[]> => {
  try {
    const q = query(
      collection(db, 'solarPanels'),
      where('userId', '==', userId)
    );

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt instanceof Timestamp 
        ? doc.data().createdAt.toDate().toISOString()
        : new Date().toISOString()
    })) as SolarPanelData[];
  } catch (error) {
    console.error('Error fetching user solar panels:', error);
    throw error;
  }
};

export const getTotalSolarPanels = async (userId: string): Promise<{
  totalPanels: number;
  totalEnergy: number;
  totalCO2Reduction: number;
}> => {
  try {
    const panels = await getUserSolarPanels(userId);
    
    return panels.reduce((acc, panel) => ({
      totalPanels: acc.totalPanels + panel.panelPercentage / 100, // Convert percentage to decimal
      totalEnergy: acc.totalEnergy + panel.energyProduction,
      totalCO2Reduction: acc.totalCO2Reduction + panel.co2Reduction
    }), {
      totalPanels: 0,
      totalEnergy: 0,
      totalCO2Reduction: 0
    });
  } catch (error) {
    console.error('Error calculating total solar panels:', error);
    throw error;
  }
};