import React, { useState, useEffect } from 'react';
import { Wallet, Copy, Check, QrCode, AlertTriangle } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { getAffiliateData, updateWithdrawalMethod, getWithdrawalMethod } from '../../services/affiliate';
import { formatCurrency } from '../../utils/format';

interface WithdrawalMethod {
  type: 'pix' | 'usdt';
  key: string;
}

const AffiliateFinance = () => {
  const { currentUser } = useAuth();
  const [earnings, setEarnings] = useState(0);
  const [loading, setLoading] = useState(true);
  const [withdrawalMethod, setWithdrawalMethod] = useState<WithdrawalMethod | null>(null);
  const [newKey, setNewKey] = useState('');
  const [copied, setCopied] = useState(false);
  const [selectedType, setSelectedType] = useState<'pix' | 'usdt'>('pix');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!currentUser) return;
      
      try {
        setLoading(true);
        const data = await getAffiliateData(currentUser.uid);
        const method = await getWithdrawalMethod(currentUser.uid);
        
        if (data) {
          setEarnings(data.earnings?.available || 0);
        }
        if (method) {
          setWithdrawalMethod(method);
          setSelectedType(method.type);
        }
      } catch (error) {
        console.error('Error fetching affiliate data:', error);
        setError('Erro ao carregar dados financeiros');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentUser]);

  const handleCopy = async () => {
    if (!withdrawalMethod?.key) return;
    
    try {
      await navigator.clipboard.writeText(withdrawalMethod.key);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error('Failed to copy:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentUser || !newKey) return;

    try {
      setLoading(true);
      setError(null);
      
      await updateWithdrawalMethod(currentUser.uid, {
        type: selectedType,
        key: newKey
      });

      setWithdrawalMethod({
        type: selectedType,
        key: newKey
      });
      setNewKey('');
    } catch (error) {
      console.error('Error updating withdrawal method:', error);
      setError('Erro ao salvar método de saque');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Financeiro</h1>
        <p className="mt-1 text-gray-600 dark:text-gray-400">
          Gerencie seus ganhos e configure métodos de saque
        </p>
      </div>

      {error && (
        <div className="mb-6 p-4 bg-red-50 dark:bg-red-900/20 text-red-600 dark:text-red-400 rounded-lg flex items-center">
          <AlertTriangle className="h-5 w-5 mr-2" />
          {error}
        </div>
      )}

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Earnings Card */}
        <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm">
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
            Saldo Disponível
          </h2>
          <div className="bg-gray-50 dark:bg-gray-700/50 rounded-lg p-4">
            <p className="text-sm text-gray-600 dark:text-gray-400 mb-1">
              Disponível para Saque
            </p>
            <p className="text-3xl font-bold text-purple-600 dark:text-purple-400">
              {formatCurrency(earnings)}
            </p>
            <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
              Saques são processados toda sexta-feira com prazo de até 24 horas
            </p>
          </div>
        </div>

        {/* Withdrawal Methods */}
        <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm">
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
            Método de Saque
          </h2>

          {withdrawalMethod ? (
            <div className="space-y-4">
              <div className="bg-gray-50 dark:bg-gray-700/50 p-4 rounded-lg">
                <div className="flex items-center justify-between mb-2">
                  <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                    {withdrawalMethod.type === 'pix' ? 'Chave PIX' : 'Carteira USDT (TRC20)'}
                  </span>
                  <button
                    onClick={handleCopy}
                    className="text-purple-600 hover:text-purple-700 dark:text-purple-400"
                  >
                    {copied ? (
                      <Check className="h-5 w-5" />
                    ) : (
                      <Copy className="h-5 w-5" />
                    )}
                  </button>
                </div>
                <p className="text-sm text-gray-600 dark:text-gray-400 break-all">
                  {withdrawalMethod.key}
                </p>
              </div>

              <button
                onClick={() => setWithdrawalMethod(null)}
                className="w-full px-4 py-2 text-purple-600 border border-purple-600 rounded-lg hover:bg-purple-50 dark:hover:bg-purple-900/20 transition-colors"
              >
                Alterar Método de Saque
              </button>
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                  Selecione o Método
                </label>
                <div className="grid grid-cols-2 gap-4">
                  <button
                    type="button"
                    onClick={() => setSelectedType('pix')}
                    className={`p-4 rounded-lg border ${
                      selectedType === 'pix'
                        ? 'border-purple-600 bg-purple-50 dark:bg-purple-900/20'
                        : 'border-gray-300 dark:border-gray-600'
                    }`}
                  >
                    <QrCode className={`h-6 w-6 mx-auto mb-2 ${
                      selectedType === 'pix'
                        ? 'text-purple-600'
                        : 'text-gray-400'
                    }`} />
                    <span className={selectedType === 'pix'
                      ? 'text-purple-600'
                      : 'text-gray-600 dark:text-gray-400'
                    }>
                      PIX
                    </span>
                  </button>

                  <button
                    type="button"
                    onClick={() => setSelectedType('usdt')}
                    className={`p-4 rounded-lg border ${
                      selectedType === 'usdt'
                        ? 'border-purple-600 bg-purple-50 dark:bg-purple-900/20'
                        : 'border-gray-300 dark:border-gray-600'
                    }`}
                  >
                    <Wallet className={`h-6 w-6 mx-auto mb-2 ${
                      selectedType === 'usdt'
                        ? 'text-purple-600'
                        : 'text-gray-400'
                    }`} />
                    <span className={selectedType === 'usdt'
                      ? 'text-purple-600'
                      : 'text-gray-600 dark:text-gray-400'
                    }>
                      USDT TRC20
                    </span>
                  </button>
                </div>
              </div>

              <div>
                <label htmlFor="key" className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                  {selectedType === 'pix' ? 'Chave PIX' : 'Endereço da Carteira'}
                </label>
                <input
                  type="text"
                  id="key"
                  value={newKey}
                  onChange={(e) => setNewKey(e.target.value)}
                  placeholder={selectedType === 'pix' ? 'Digite sua chave PIX' : 'Digite o endereço da sua carteira'}
                  className="w-full px-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:text-white"
                  required
                />
              </div>

              <button
                type="submit"
                disabled={loading || !newKey}
                className="w-full bg-purple-600 text-white px-4 py-2 rounded-lg hover:bg-purple-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {loading ? 'Salvando...' : 'Salvar Método de Saque'}
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default AffiliateFinance;