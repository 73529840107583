import React from 'react';
import { Routes, Route } from 'react-router-dom';
import DashboardLayout from './dashboard/DashboardLayout';
import LoginForm from './auth/LoginForm';
import RegisterForm from './auth/RegisterForm';
import LandingPage from './LandingPage';
import AccountPage from './account/AccountPage';
import TransactionsPage from './transactions/TransactionsPage';
import SettingsPage from './settings/SettingsPage';
import SecurityPage from './security/SecurityPage';
import CreditAnalysisPage from './credit/CreditAnalysisPage';
import CareersForm from './careers/CareersForm';
import AffiliateLogin from './affiliate/AffiliateLogin';
import AffiliateDashboard from './affiliate/AffiliateDashboard';
import AdminDashboard from './admin/AdminDashboard';
import UserDashboardView from './admin/UserDashboardView';

const App = () => {
  return (
    <Routes>
      <Route path="/dashboard" element={<DashboardLayout />} />
      <Route path="/account" element={<AccountPage />} />
      <Route path="/transactions" element={<TransactionsPage />} />
      <Route path="/settings" element={<SettingsPage />} />
      <Route path="/security" element={<SecurityPage />} />
      <Route path="/credit" element={<CreditAnalysisPage />} />
      <Route path="/careers" element={<CareersForm />} />
      <Route path="/affiliate/login" element={<AffiliateLogin />} />
      <Route path="/affiliate/dashboard" element={<AffiliateDashboard />} />
      <Route path="/affiliate/management" element={<AffiliateDashboard />} />
      <Route path="/affiliate/finance" element={<AffiliateDashboard />} />
      <Route path="/affiliate/settings" element={<AffiliateDashboard />} />
      <Route path="/admin" element={<AdminDashboard />} />
      <Route path="/admin/users/:userId/dashboard" element={<UserDashboardView />} />
      <Route path="/login" element={<LoginForm />} />
      <Route path="/register" element={<RegisterForm />} />
      <Route path="/" element={<LandingPage />} />
    </Routes>
  );
};

export default App;
