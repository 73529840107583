import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { getFirestore, collection, getDocs, query, where } from 'firebase/firestore';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const AffiliateChart = () => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore();
      const investmentsRef = collection(db, 'investments');
      const affiliatesRef = collection(db, 'affiliates');
      const activeInvestmentsQuery = query(investmentsRef, where('status', '==', 'active'));

      const investmentsSnapshot = await getDocs(activeInvestmentsQuery);
      const investments = investmentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      // Calcular ganhos e indicações
      const earningsByMonth = {};
      const referralsByMonth = {};

      for (const investment of investments) {
        const month = new Date(investment.createdAt).toLocaleString('default', { month: 'short' });
        const amount = investment.amount;

        // Adicionar ganhos ao mês
        if (!earningsByMonth[month]) earningsByMonth[month] = 0;
        earningsByMonth[month] += amount * 0.1; // Com base no nível 1

        // Contar referências
        if (!referralsByMonth[month]) referralsByMonth[month] = 0;
        referralsByMonth[month] += 1;
      }

      setChartData({
        labels: Object.keys(earningsByMonth),
        datasets: [
          {
            label: 'Ganhos',
            data: Object.values(earningsByMonth),
            fill: true,
            backgroundColor: 'rgba(147, 51, 234, 0.1)',
            borderColor: 'rgba(147, 51, 234, 0.8)',
            tension: 0.4,
          },
          {
            label: 'Indicações',
            data: Object.values(referralsByMonth),
            fill: true,
            backgroundColor: 'rgba(59, 130, 246, 0.1)',
            borderColor: 'rgba(59, 130, 246, 0.8)',
            tension: 0.4,
          },
        ],
      });
    };

    fetchData();
  }, []);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Line data={chartData} options={options} />;
};

export default AffiliateChart;
