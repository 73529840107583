import React, { useState, useEffect } from 'react';
import { ArrowRight, ChevronLeft, ChevronRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Logo from './Logo';

const images = [
  {
    url: "https://images.unsplash.com/photo-1497436072909-60f360e1d4b1",
    alt: "Energia Solar",
    caption: "Investimento em energia limpa"
  },
  {
    url: "https://images.unsplash.com/photo-1508514177221-188b1cf16e9d",
    alt: "Natureza Preservada",
    caption: "Compromisso com a sustentabilidade"
  },
  {
    url: "https://images.unsplash.com/photo-1509391366360-2e959784a276",
    alt: "Painéis Solares",
    caption: "Tecnologia a serviço do meio ambiente"
  },
  {
    url: "https://images.unsplash.com/photo-1466611653911-95081537e5b7",
    alt: "Energia Renovável",
    caption: "Futuro sustentável"
  }
];

const Hero = () => {
  const navigate = useNavigate();
  const [currentImage, setCurrentImage] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      handleNextImage();
    }, 5000);

    return () => clearInterval(timer);
  }, [currentImage]);

  const handlePrevImage = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentImage((prev) => (prev === 0 ? images.length - 1 : prev - 1));
    setTimeout(() => setIsTransitioning(false), 500);
  };

  const handleNextImage = () => {
    if (isTransitioning) return;
    setIsTransitioning(true);
    setCurrentImage((prev) => (prev === images.length - 1 ? 0 : prev + 1));
    setTimeout(() => setIsTransitioning(false), 500);
  };

  return (
    <div className="relative bg-gradient-to-br from-purple-50 to-white dark:from-gray-900 dark:to-gray-800 pt-24 pb-16">
      {/* Decorative background elements */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute -top-24 -left-24 w-96 h-96 bg-purple-100 dark:bg-purple-900/20 rounded-full mix-blend-multiply filter blur-xl opacity-50"></div>
        <div className="absolute -bottom-24 -right-24 w-96 h-96 bg-purple-100 dark:bg-purple-900/20 rounded-full mix-blend-multiply filter blur-xl opacity-50"></div>
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        {/* Logo Animation Container */}
        <div className="flex flex-col items-center mb-12 animate-fade-in">
          <div className="transform hover:scale-105 transition-transform duration-300">
            <Logo size="lg" className="mb-6" />
          </div>
          <div className="h-1 w-24 bg-gradient-to-r from-purple-400 to-purple-600 rounded-full"></div>
        </div>

        <div className="grid lg:grid-cols-2 gap-12 items-center">
          <div>
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900 dark:text-white leading-tight">
              Invista com <span className="text-purple-600 dark:text-purple-400">Segurança</span> e <span className="text-purple-600 dark:text-purple-400">Sustentabilidade</span>
            </h1>
            <p className="mt-6 text-lg text-gray-600 dark:text-gray-300 leading-relaxed">
              Alcance rendimentos de 8% a 12% ao mês com o HK Bank. Transforme seu futuro financeiro através de investimentos em energia solar, combinando inovação e sustentabilidade.
            </p>
            <div className="mt-8 flex flex-col sm:flex-row gap-4">
              <button 
                onClick={() => navigate('/register')}
                className="inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-lg text-white bg-purple-600 hover:bg-purple-700 transition-colors duration-200 shadow-lg hover:shadow-xl transform hover:-translate-y-0.5"
              >
                Comece a Investir
                <ArrowRight className="ml-2 h-5 w-5" />
              </button>
              <button 
                onClick={() => {
                  const featuresSection = document.getElementById('features');
                  featuresSection?.scrollIntoView({ behavior: 'smooth' });
                }}
                className="inline-flex items-center justify-center px-6 py-3 border border-purple-600 text-base font-medium rounded-lg text-purple-600 dark:text-purple-400 bg-transparent hover:bg-purple-50 dark:hover:bg-purple-900/20 transition-colors duration-200"
              >
                Saiba Mais
              </button>
            </div>
          </div>
          <div className="relative">
            {/* Image Carousel */}
            <div className="relative overflow-hidden rounded-2xl shadow-2xl">
              <div className="absolute inset-0 bg-gradient-to-tr from-purple-500/20 to-transparent z-10"></div>
              
              {/* Navigation Buttons */}
              <button
                onClick={handlePrevImage}
                className="absolute left-4 top-1/2 -translate-y-1/2 z-20 p-2 rounded-full bg-white/80 dark:bg-gray-800/80 text-gray-800 dark:text-white hover:bg-white dark:hover:bg-gray-800 transition-colors"
              >
                <ChevronLeft className="h-6 w-6" />
              </button>
              <button
                onClick={handleNextImage}
                className="absolute right-4 top-1/2 -translate-y-1/2 z-20 p-2 rounded-full bg-white/80 dark:bg-gray-800/80 text-gray-800 dark:text-white hover:bg-white dark:hover:bg-gray-800 transition-colors"
              >
                <ChevronRight className="h-6 w-6" />
              </button>

              {/* Images */}
              <div
                className="relative w-full h-[400px] transition-transform duration-500 ease-in-out"
                style={{ transform: `translateX(-${currentImage * 100}%)` }}
              >
                <div className="absolute flex">
                  {images.map((image, index) => (
                    <div
                      key={index}
                      className="relative w-full h-[400px] flex-shrink-0"
                      style={{ width: '100%' }}
                    >
                      <img
                        src={`${image.url}?ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80`}
                        alt={image.alt}
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/60 to-transparent">
                        <p className="text-white text-lg font-medium">{image.caption}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/* Dots Indicator */}
              <div className="absolute bottom-4 left-1/2 -translate-x-1/2 z-20 flex space-x-2">
                {images.map((_, index) => (
                  <button
                    key={index}
                    onClick={() => setCurrentImage(index)}
                    className={`w-2 h-2 rounded-full transition-colors ${
                      currentImage === index
                        ? 'bg-white'
                        : 'bg-white/50 hover:bg-white/75'
                    }`}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;