import React, { useState } from 'react';
import { Lock } from 'lucide-react';

interface PinVerificationModalProps {
  onVerify: (pin: string) => void;
  onClose: () => void;
}

const PinVerificationModal: React.FC<PinVerificationModalProps> = ({ onVerify, onClose }) => {
  const [pin, setPin] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (pin.length !== 4) {
      setError('O PIN deve ter 4 dígitos');
      return;
    }
    onVerify(pin);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-md w-full mx-4">
        <div className="flex items-center justify-center mb-4">
          <div className="p-3 bg-purple-100 dark:bg-purple-900/50 rounded-full">
            <Lock className="h-6 w-6 text-purple-600 dark:text-purple-400" />
          </div>
        </div>

        <h3 className="text-lg font-semibold text-center text-gray-900 dark:text-white mb-4">
          Verificação de PIN
        </h3>

        <p className="text-sm text-gray-600 dark:text-gray-400 text-center mb-6">
          Digite seu PIN de 4 dígitos para confirmar esta ação
        </p>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <input
              type="password"
              inputMode="numeric"
              maxLength={4}
              value={pin}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, '');
                setPin(value);
                setError('');
              }}
              className="block w-full text-center text-2xl tracking-widest py-2 px-3 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:text-white"
              placeholder="****"
            />
            {error && (
              <p className="mt-1 text-sm text-red-600 dark:text-red-400 text-center">
                {error}
              </p>
            )}
          </div>

          <div className="flex space-x-3">
            <button
              type="button"
              onClick={onClose}
              className="flex-1 px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg transition-colors"
            >
              Cancelar
            </button>
            <button
              type="submit"
              disabled={pin.length !== 4}
              className="flex-1 px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Confirmar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PinVerificationModal;