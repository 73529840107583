import React, { useState, useEffect } from 'react';
import { Users, DollarSign, UserCheck, AlertTriangle, MessageSquare } from 'lucide-react';
import { useUser } from '../../contexts/UserContext';
import DashboardLayout from '../dashboard/DashboardLayout';
import PendingApprovals from './PendingApprovals';
import WithdrawalRequests from './WithdrawalRequests';
import UsersList from './UsersList';
import SupportDashboard from './support/SupportDashboard';
import FinancialMetrics from './FinancialMetrics';
import FilterBar from './FilterBar';
import { getAllUsers } from '../../services/admin';
import { getPendingApplications } from '../../services/affiliate';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../config/firebase';

const AdminDashboard = () => {
  const { userData } = useUser();
  const [activeTab, setActiveTab] = useState<'overview' | 'support'>('overview');
  const [stats, setStats] = useState({
    totalUsers: 0,
    pendingApprovals: 0,
    pendingWithdrawals: 0,
    activeAffiliates: 0
  });
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [dateRange, setDateRange] = useState('last30days');
  const [transactionType, setTransactionType] = useState('all');
  const [financialData, setFinancialData] = useState({
    totalRevenue: 0,
    totalInvestments: 0,
    creditContracts: 0,
    affiliateCommissions: 0
  });

  useEffect(() => {
    const fetchStats = async () => {
      try {
        setLoading(true);
        const users = await getAllUsers();
        const pendingApps = await getPendingApplications();
        
        // Get financial data
        const investmentsRef = collection(db, 'investments');
        const investmentsSnap = await getDocs(investmentsRef);
        const totalInvestments = investmentsSnap.docs.reduce((sum, doc) => sum + (doc.data().amount || 0), 0);

        const creditRef = collection(db, 'creditContracts');
        const creditSnap = await getDocs(creditRef);
        const creditContracts = creditSnap.size;

        const commissionsRef = collection(db, 'affiliateCommissions');
        const commissionsSnap = await getDocs(commissionsRef);
        const totalCommissions = commissionsSnap.docs.reduce((sum, doc) => sum + (doc.data().amount || 0), 0);

        setStats({
          totalUsers: users.length,
          pendingApprovals: pendingApps.length,
          pendingWithdrawals: 0,
          activeAffiliates: users.filter(user => user.type === 'affiliate' && user.status === 'active').length
        });

        setFinancialData({
          totalRevenue: totalInvestments + totalCommissions,
          totalInvestments,
          creditContracts,
          affiliateCommissions: totalCommissions
        });
      } catch (error) {
        console.error('Error fetching admin stats:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  return (
    <DashboardLayout>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Tab Navigation */}
        <div className="mb-8 flex space-x-4">
          <button
            onClick={() => setActiveTab('overview')}
            className={`px-4 py-2 rounded-lg transition-colors ${
              activeTab === 'overview'
                ? 'bg-purple-600 text-white'
                : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700'
            }`}
          >
            Visão Geral
          </button>
          <button
            onClick={() => setActiveTab('support')}
            className={`px-4 py-2 rounded-lg transition-colors ${
              activeTab === 'support'
                ? 'bg-purple-600 text-white'
                : 'text-gray-600 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700'
            }`}
          >
            Suporte ao Cliente
          </button>
        </div>

        {activeTab === 'overview' ? (
          <>
            <div className="mb-8">
              <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
                Painel Administrativo
              </h1>
              <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                Gerencie usuários, aprovações e transações
              </p>
            </div>

            {/* Financial Metrics */}
            <FinancialMetrics data={financialData} />

            {/* Filters */}
            <div className="mt-8">
              <FilterBar
                searchTerm={searchTerm}
                onSearchChange={setSearchTerm}
                dateRange={dateRange}
                onDateRangeChange={setDateRange}
                transactionType={transactionType}
                onTransactionTypeChange={setTransactionType}
              />
            </div>

            {/* Quick Stats */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
              <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm">
                <div className="flex items-center">
                  <div className="p-2 bg-purple-100 dark:bg-purple-900/50 rounded-lg">
                    <Users className="h-6 w-6 text-purple-600 dark:text-purple-400" />
                  </div>
                  <div className="ml-4">
                    <p className="text-sm font-medium text-gray-600 dark:text-gray-400">
                      Total de Usuários
                    </p>
                    <p className="text-2xl font-semibold text-gray-900 dark:text-white">
                      {loading ? '...' : stats.totalUsers}
                    </p>
                  </div>
                </div>
              </div>

              <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm">
                <div className="flex items-center">
                  <div className="p-2 bg-yellow-100 dark:bg-yellow-900/50 rounded-lg">
                    <AlertTriangle className="h-6 w-6 text-yellow-600 dark:text-yellow-400" />
                  </div>
                  <div className="ml-4">
                    <p className="text-sm font-medium text-gray-600 dark:text-gray-400">
                      Aprovações Pendentes
                    </p>
                    <p className="text-2xl font-semibold text-gray-900 dark:text-white">
                      {loading ? '...' : stats.pendingApprovals}
                    </p>
                  </div>
                </div>
              </div>

              <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm">
                <div className="flex items-center">
                  <div className="p-2 bg-green-100 dark:bg-green-900/50 rounded-lg">
                    <DollarSign className="h-6 w-6 text-green-600 dark:text-green-400" />
                  </div>
                  <div className="ml-4">
                    <p className="text-sm font-medium text-gray-600 dark:text-gray-400">
                      Saques Pendentes
                    </p>
                    <p className="text-2xl font-semibold text-gray-900 dark:text-white">
                      {loading ? '...' : stats.pendingWithdrawals}
                    </p>
                  </div>
                </div>
              </div>

              <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm">
                <div className="flex items-center">
                  <div className="p-2 bg-blue-100 dark:bg-blue-900/50 rounded-lg">
                    <UserCheck className="h-6 w-6 text-blue-600 dark:text-blue-400" />
                  </div>
                  <div className="ml-4">
                    <p className="text-sm font-medium text-gray-600 dark:text-gray-400">
                      Afiliados Ativos
                    </p>
                    <p className="text-2xl font-semibold text-gray-900 dark:text-white">
                      {loading ? '...' : stats.activeAffiliates}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/* Main Content */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
              <div>
                <PendingApprovals />
              </div>
              <div>
                <WithdrawalRequests />
              </div>
            </div>

            {/* Users List */}
            <div className="mt-8">
              <UsersList />
            </div>
          </>
        ) : (
          <SupportDashboard />
        )}
      </div>
    </DashboardLayout>
  );
};

export default AdminDashboard;