import { 
  collection,
  addDoc,
  query,
  where,
  orderBy,
  getDocs,
  Timestamp,
  serverTimestamp
} from 'firebase/firestore';
import { db } from '../config/firebase';

export interface Transaction {
  id: string;
  userId: string;
  type: 'investment' | 'withdrawal' | 'commission' | 'credit';
  amount: number;
  status: 'pending' | 'completed' | 'cancelled';
  description: string;
  createdAt: string;
  processedAt?: string;
  reference?: string;
}

export const createTransaction = async (data: Omit<Transaction, 'id' | 'createdAt'>) => {
  try {
    const transactionRef = await addDoc(collection(db, 'transactions'), {
      ...data,
      createdAt: serverTimestamp()
    });

    return transactionRef.id;
  } catch (error) {
    console.error('Error creating transaction:', error);
    throw error;
  }
};

export const getUserTransactions = async (userId: string): Promise<Transaction[]> => {
  try {
    const q = query(
      collection(db, 'transactions'),
      where('userId', '==', userId),
      orderBy('createdAt', 'desc')
    );

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => {
      const data = doc.data();
      return {
        id: doc.id,
        ...data,
        createdAt: data.createdAt instanceof Timestamp 
          ? data.createdAt.toDate().toISOString()
          : new Date().toISOString(),
        processedAt: data.processedAt instanceof Timestamp
          ? data.processedAt.toDate().toISOString()
          : data.processedAt
      } as Transaction;
    });
  } catch (error) {
    console.error('Error fetching user transactions:', error);
    throw error;
  }
};

export const getTransactionsByDateRange = async (
  startDate: Date,
  endDate: Date,
  type?: Transaction['type']
): Promise<Transaction[]> => {
  try {
    let q = query(
      collection(db, 'transactions'),
      where('createdAt', '>=', startDate),
      where('createdAt', '<=', endDate),
      orderBy('createdAt', 'desc')
    );

    if (type) {
      q = query(q, where('type', '==', type));
    }

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map(doc => {
      const data = doc.data();
      return {
        id: doc.id,
        ...data,
        createdAt: data.createdAt instanceof Timestamp 
          ? data.createdAt.toDate().toISOString()
          : new Date().toISOString(),
        processedAt: data.processedAt instanceof Timestamp
          ? data.processedAt.toDate().toISOString()
          : data.processedAt
      } as Transaction;
    });
  } catch (error) {
    console.error('Error fetching transactions by date range:', error);
    throw error;
  }
};

export const getTransactionStats = async () => {
  try {
    const transactions = await getDocs(collection(db, 'transactions'));
    
    const stats = transactions.docs.reduce((acc, doc) => {
      const data = doc.data();
      const amount = data.amount || 0;
      
      switch (data.type) {
        case 'investment':
          acc.totalInvestments += amount;
          break;
        case 'commission':
          acc.totalCommissions += amount;
          break;
        case 'credit':
          acc.totalCreditContracts++;
          break;
      }
      
      return acc;
    }, {
      totalInvestments: 0,
      totalCommissions: 0,
      totalCreditContracts: 0
    });

    return stats;
  } catch (error) {
    console.error('Error fetching transaction stats:', error);
    throw error;
  }
};