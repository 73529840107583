import React, { useEffect, useState } from 'react';
import { Copy, Check, Share2, RefreshCcw } from 'lucide-react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { generateReferralCode } from '../../services/affiliate';
import { useAuth } from '../../contexts/AuthContext';

interface ReferralLinkProps {
  adminView?: boolean;
  userId?: string;
}

const ReferralLink: React.FC<ReferralLinkProps> = ({ adminView = false, userId }) => {
  const { currentUser } = useAuth(); // Contexto de autenticação
  const [copied, setCopied] = useState(false);
  const [shareError, setShareError] = useState<string | null>(null);
  const [referralCode, setReferralCode] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const fetchOrCreateReferralCode = async () => {
    const resolvedUserId = userId || currentUser?.uid;

    if (!resolvedUserId || resolvedUserId.trim() === '') {
      console.error('userId não foi fornecido ou está inválido.');
      setShareError('Usuário inválido. Verifique sua autenticação.');
      return;
    }

    try {
      setLoading(true);
      setShareError(null);

      const userRef = doc(db, 'users', resolvedUserId);
      const userDoc = await getDoc(userRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData?.referralCode) {
          setReferralCode(userData.referralCode);
        } else {
          const newReferralCode = generateReferralCode(resolvedUserId);
          await updateDoc(userRef, { referralCode: newReferralCode });
          setReferralCode(newReferralCode);
        }
      } else {
        console.error('Usuário não encontrado no Firestore.');
        setShareError('Usuário não encontrado. Tente novamente.');
      }
    } catch (error) {
      console.error('Erro ao buscar ou criar código de referência:', error);
      setShareError('Erro ao gerar código de referência. Tente novamente mais tarde.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrCreateReferralCode();
  }, [userId, currentUser]);

  const referralLink = referralCode
    ? `https://hkbank.digital/careers?ref=${referralCode}`
    : 'Gerando link...';

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(referralLink);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
      setShareError(null);
    } catch (error) {
      setShareError('Não foi possível copiar o link. Por favor, copie manualmente.');
      console.error('Erro ao copiar:', error);
    }
  };

  const shareLink = async () => {
    if (adminView) return;

    try {
      if (navigator.share) {
        await navigator.share({
          title: 'HK Bank - Investimentos',
          text: 'Invista com segurança e obtenha ótimos rendimentos!',
          url: referralLink,
        });
        setShareError(null);
      } else {
        await copyToClipboard();
      }
    } catch (error) {
      if (error instanceof Error && error.name !== 'AbortError') {
        setShareError('Não foi possível compartilhar. O link foi copiado para a área de transferência.');
        await copyToClipboard();
      }
    }
  };

  return (
    <div className="space-y-4">
      <div className="p-3 bg-gray-50 dark:bg-gray-700/50 rounded-lg break-all">
        <code className="text-xs sm:text-sm text-gray-800 dark:text-gray-200">
          {loading ? 'Carregando...' : referralLink}
        </code>
      </div>

      {shareError && (
        <div className="text-xs sm:text-sm text-amber-600 dark:text-amber-400 bg-amber-50 dark:bg-amber-900/20 p-2 rounded">
          {shareError}
        </div>
      )}

      {!adminView && (
        <div className="flex flex-col sm:flex-row gap-2 sm:gap-4">
          <button
            onClick={copyToClipboard}
            disabled={!referralCode || loading}
            className="flex-1 flex items-center justify-center px-3 sm:px-4 py-2 border border-purple-600 rounded-lg text-purple-600 hover:bg-purple-50 dark:hover:bg-purple-900/20 transition-colors text-sm"
          >
            {copied ? (
              <>
                <Check className="h-4 w-4 sm:h-5 sm:w-5 mr-2" />
                Copiado!
              </>
            ) : (
              <>
                <Copy className="h-4 w-4 sm:h-5 sm:w-5 mr-2" />
                Copiar Link
              </>
            )}
          </button>

          <button
            onClick={shareLink}
            disabled={!referralCode || loading}
            className="flex-1 flex items-center justify-center px-3 sm:px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 transition-colors text-sm"
          >
            <Share2 className="h-4 w-4 sm:h-5 sm:w-5 mr-2" />
            Compartilhar
          </button>

          <button
            onClick={fetchOrCreateReferralCode}
            disabled={loading}
            className="flex-1 flex items-center justify-center px-3 sm:px-4 py-2 border border-blue-600 rounded-lg text-blue-600 hover:bg-blue-50 dark:hover:bg-blue-900/20 transition-colors text-sm"
          >
            <RefreshCcw className="h-4 w-4 sm:h-5 sm:w-5 mr-2" />
            Gerar Código
          </button>
        </div>
      )}

      <div className="text-xs sm:text-sm text-gray-600 dark:text-gray-400">
        <p className="font-medium mb-2">Comissões:</p>
        <ul className="space-y-1">
          <li className="flex items-center">
            <span className="inline-block w-2 h-2 rounded-full bg-blue-500 mr-2"></span>
            <span className="text-blue-600 dark:text-blue-400 font-medium">Nível 1:</span>
            <span className="ml-2">10% de comissão</span>
          </li>
          <li className="flex items-center">
            <span className="inline-block w-2 h-2 rounded-full bg-green-500 mr-2"></span>
            <span className="text-green-600 dark:text-green-400 font-medium">Nível 2:</span>
            <span className="ml-2">5% de comissão</span>
          </li>
          <li className="flex items-center">
            <span className="inline-block w-2 h-2 rounded-full bg-purple-500 mr-2"></span>
            <span className="text-purple-600 dark:text-purple-400 font-medium">Nível 3:</span>
            <span className="ml-2">3% de comissão</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ReferralLink;
