import React from 'react';
import { Instagram, Youtube, Twitter, MessageCircle, Phone, Facebook } from 'lucide-react';

export type SocialNetwork = 'instagram' | 'tiktok' | 'youtube' | 'twitter' | 'telegram' | 'whatsapp' | 'facebook';

interface SocialNetworkButtonProps {
  network: SocialNetwork;
  onClick: () => void;
}

const SocialNetworkButton: React.FC<SocialNetworkButtonProps> = ({ network, onClick }) => {
  const getIcon = () => {
    switch (network) {
      case 'instagram':
        return <Instagram className="h-5 w-5" />;
      case 'facebook':
        return <Facebook className="h-5 w-5" />;
      case 'tiktok':
        return (
          <svg 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            className="h-5 w-5"
          >
            <path d="M9 12a4 4 0 1 0 4 4V4a5 5 0 0 0 5 5" />
          </svg>
        );
      case 'youtube':
        return <Youtube className="h-5 w-5" />;
      case 'twitter':
        return <Twitter className="h-5 w-5" />;
      case 'telegram':
        return <MessageCircle className="h-5 w-5" />;
      case 'whatsapp':
        return <Phone className="h-5 w-5" />;
      default:
        return null;
    }
  };

  return (
    <button
      type="button"
      onClick={onClick}
      className="inline-flex items-center px-3 py-1.5 border border-purple-600 rounded-full text-sm text-purple-600 hover:bg-purple-50 dark:hover:bg-purple-900/20 transition-all duration-200"
    >
      {getIcon()}
      <span className="ml-2">Adicionar</span>
    </button>
  );
};

export default SocialNetworkButton;