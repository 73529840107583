import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyArzjtKRENjFFro7FS7EXMSYqpiLZU9n2A",
  authDomain: "hk-bank-3d586.firebaseapp.com",
  projectId: "hk-bank-3d586",
  storageBucket: "hk-bank-3d586.appspot.com",
  messagingSenderId: "616689626286",
  appId: "1:616689626286:web:64fb3a0189a3346f59d13f"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export default app;