import React, { useState } from 'react';
import { ArrowRight, ArrowLeft, Calculator, FileText, QrCode } from 'lucide-react';
import { formatCurrency } from '../../utils/format';
import { calculateLoan } from '../../utils/credit';

interface CreditRequestFormProps {
  onClose: () => void;
}

const CreditRequestForm: React.FC<CreditRequestFormProps> = ({ onClose }) => {
  const [step, setStep] = useState(1);
  const [amount, setAmount] = useState(1000);
  const [installments, setInstallments] = useState(12);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [loading, setLoading] = useState(false);

  const minAmount = 1000;
  const maxAmount = 50000;
  const minInstallments = 12;
  const maxInstallments = 120;
  const serviceCharge = 197; // Fixed service charge in BRL

  const { installmentAmount, totalAmount } = calculateLoan(amount, installments, 0.012);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      // Add submission logic here
      await new Promise(resolve => setTimeout(resolve, 1500));
      // Handle success
    } catch (error) {
      console.error('Error submitting credit request:', error);
    } finally {
      setLoading(false);
    }
  };

  const renderQRCode = () => (
    <div className="bg-white dark:bg-gray-800 p-6 rounded-lg text-center">
      <h3 className="text-xl font-semibold text-gray-900 dark:text-white mb-6">
        Pagamento do Serviço
      </h3>
      <div className="flex justify-center mb-6">
        <img
          src={`https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${encodeURIComponent(
            `Pagamento HK Bank - Serviço de Regularização de Crédito - R$ ${serviceCharge}`
          )}`}
          alt="QR Code para pagamento"
          className="w-64 h-64"
        />
      </div>
      <div className="space-y-2 mb-6">
        <p className="text-lg font-semibold text-purple-600 dark:text-purple-400">
          {formatCurrency(serviceCharge)}
        </p>
        <p className="text-sm text-gray-600 dark:text-gray-400">
          Escaneie o QR Code para realizar o pagamento
        </p>
      </div>
      <button
        onClick={onClose}
        className="w-full px-4 py-2 bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300 rounded-lg hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
      >
        Fechar
      </button>
    </div>
  );

  const renderStepContent = () => {
    if (termsAccepted) {
      return renderQRCode();
    }

    switch (step) {
      case 1:
        return (
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Valor Desejado
              </label>
              <div className="relative">
                <input
                  type="range"
                  min={minAmount}
                  max={maxAmount}
                  step={100}
                  value={amount}
                  onChange={(e) => setAmount(Number(e.target.value))}
                  className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                />
                <div className="mt-2 text-center">
                  <span className="text-2xl font-bold text-purple-600 dark:text-purple-400">
                    {formatCurrency(amount)}
                  </span>
                </div>
              </div>
              <div className="flex justify-between text-sm text-gray-500 dark:text-gray-400 mt-1">
                <span>{formatCurrency(minAmount)}</span>
                <span>{formatCurrency(maxAmount)}</span>
              </div>
            </div>

            <button
              onClick={() => setStep(2)}
              className="w-full flex items-center justify-center space-x-2 bg-purple-600 text-white px-4 py-2 rounded-lg hover:bg-purple-700 transition-colors"
            >
              <span>Próximo</span>
              <ArrowRight className="h-4 w-4" />
            </button>
          </div>
        );

      case 2:
        return (
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
                Número de Parcelas
              </label>
              <div className="relative">
                <input
                  type="range"
                  min={minInstallments}
                  max={maxInstallments}
                  step={1}
                  value={installments}
                  onChange={(e) => setInstallments(Number(e.target.value))}
                  className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                />
                <div className="mt-2 text-center">
                  <span className="text-2xl font-bold text-purple-600 dark:text-purple-400">
                    {installments} parcelas
                  </span>
                </div>
              </div>
              <div className="flex justify-between text-sm text-gray-500 dark:text-gray-400 mt-1">
                <span>{minInstallments}x</span>
                <span>{maxInstallments}x</span>
              </div>
            </div>

            <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
              <div className="flex items-center justify-between mb-2">
                <span className="text-sm text-gray-600 dark:text-gray-400">Taxa de Juros</span>
                <span className="font-medium text-purple-600 dark:text-purple-400">
                  1.2% a.m.
                </span>
              </div>
              <div className="flex items-center justify-between mb-2">
                <span className="text-sm text-gray-600 dark:text-gray-400">Valor da Parcela</span>
                <span className="font-medium text-purple-600 dark:text-purple-400">
                  {formatCurrency(installmentAmount)}
                </span>
              </div>
              <div className="flex items-center justify-between pt-2 border-t border-gray-200 dark:border-gray-600">
                <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                  Total a Pagar
                </span>
                <span className="font-bold text-purple-600 dark:text-purple-400">
                  {formatCurrency(totalAmount)}
                </span>
              </div>
            </div>

            <div className="flex space-x-3">
              <button
                onClick={() => setStep(1)}
                className="flex-1 flex items-center justify-center space-x-2 border border-gray-300 dark:border-gray-600 text-gray-700 dark:text-gray-300 px-4 py-2 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors"
              >
                <ArrowLeft className="h-4 w-4" />
                <span>Voltar</span>
              </button>
              <button
                onClick={() => setStep(3)}
                className="flex-1 flex items-center justify-center space-x-2 bg-purple-600 text-white px-4 py-2 rounded-lg hover:bg-purple-700 transition-colors"
              >
                <span>Próximo</span>
                <ArrowRight className="h-4 w-4" />
              </button>
            </div>
          </div>
        );

      case 3:
        return (
          <div className="space-y-6">
            <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
              <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
                Termos e Condições
              </h3>
              <div className="max-h-[400px] overflow-y-auto mb-4 prose dark:prose-invert prose-sm">
                <div className="space-y-4 text-gray-600 dark:text-gray-400">
                  <h4 className="font-semibold text-gray-900 dark:text-white">
                    Termos e Condições para o Serviço de Regularização e Recuperação de Crédito – HK Bank
                  </h4>

                  <section>
                    <h5 className="font-semibold">1. Objeto do Contrato</h5>
                    <p>O presente Termo de Condições regula a prestação do serviço de Regularização de Limpeza de Nome e Abertura de Crédito, oferecido pelo HK Bank, com o objetivo de recuperar o crédito do contratante e melhorar o seu score financeiro.</p>
                  </section>

                  <section>
                    <h5 className="font-semibold">2. Valor do Serviço</h5>
                    <p>Para iniciar o processo, será cobrado um valor fixo de R$ 197,00. Este valor corresponde à abertura do processo de análise e recuperação de crédito, bem como à assessoria para o aumento de score.</p>
                  </section>

                  <section>
                    <h5 className="font-semibold">3. Serviços Incluídos</h5>
                    <p>O serviço contratado inclui:</p>
                    <ul className="list-disc pl-4 space-y-1">
                      <li>Análise de pendências financeiras no CPF do contratante.</li>
                      <li>Negociação com credores em nome do contratante para regularização de dívidas.</li>
                      <li>Consultoria para estratégias de aumento de score de crédito.</li>
                      <li>Emissão de relatórios mensais sobre o andamento do processo.</li>
                    </ul>
                  </section>

                  <section>
                    <h5 className="font-semibold">4. Exclusões</h5>
                    <p>Este serviço não garante a exclusão automática de registros negativos, estando condicionado à aceitação dos credores e às normativas vigentes.</p>
                  </section>

                  <section>
                    <h5 className="font-semibold">5. Prazo para Conclusão</h5>
                    <p>O prazo estimado para a regularização do nome varia conforme a complexidade do caso e a agilidade das instituições financeiras envolvidas, podendo levar de 30 a 120 dias.</p>
                  </section>

                  <section>
                    <h5 className="font-semibold">6. Obrigações do Contratante</h5>
                    <ul className="list-disc pl-4 space-y-1">
                      <li>Fornecer informações verídicas e documentos solicitados pelo HK Bank.</li>
                      <li>Pagar pontualmente o valor de R$ 197,00 para o início do serviço.</li>
                      <li>Cumprir os prazos e orientações informados pela equipe do HK Bank.</li>
                    </ul>
                  </section>

                  <section>
                    <h5 className="font-semibold">7. Obrigações do HK Bank</h5>
                    <ul className="list-disc pl-4 space-y-1">
                      <li>Prestar o serviço com diligência e transparência, mantendo o contratante informado sobre o andamento do processo.</li>
                      <li>Proteger as informações e dados pessoais do contratante, em conformidade com a LGPD.</li>
                    </ul>
                  </section>

                  <section>
                    <h5 className="font-semibold">8. Garantias e Limitações de Responsabilidade</h5>
                    <ul className="list-disc pl-4 space-y-1">
                      <li>O HK Bank não se responsabiliza por recusas de credores ou instituições financeiras em excluir registros negativos.</li>
                      <li>O aumento de score está sujeito a critérios definidos pelos birôs de crédito.</li>
                    </ul>
                  </section>

                  <section>
                    <h5 className="font-semibold">9. Cancelamento e Reembolso</h5>
                    <ul className="list-disc pl-4 space-y-1">
                      <li>O contratante pode cancelar o serviço dentro de 7 dias úteis após o pagamento, com reembolso integral.</li>
                      <li>Após o período de 7 dias, não será efetuado reembolso, exceto em casos de falhas comprovadas.</li>
                    </ul>
                  </section>

                  <section>
                    <h5 className="font-semibold">10. Foro</h5>
                    <p>Fica eleito o foro da comarca de São Paulo, para dirimir quaisquer dúvidas ou questões relativas ao presente Termo.</p>
                  </section>
                </div>
              </div>

              <div className="flex items-center mb-6">
                <input
                  type="checkbox"
                  id="terms"
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                  className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
                />
                <label htmlFor="terms" className="ml-2 block text-sm text-gray-700 dark:text-gray-300">
                  Li e aceito os termos e condições
                </label>
              </div>
            </div>

            <div className="flex space-x-3">
              <button
                onClick={() => setStep(2)}
                className="flex-1 flex items-center justify-center space-x-2 border border-gray-300 dark:border-gray-600 text-gray-700 dark:text-gray-300 px-4 py-2 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-700 transition-colors"
              >
                <ArrowLeft className="h-4 w-4" />
                <span>Voltar</span>
              </button>
              <button
                onClick={() => setTermsAccepted(true)}
                disabled={!termsAccepted}
                className="flex-1 flex items-center justify-center space-x-2 bg-purple-600 text-white px-4 py-2 rounded-lg hover:bg-purple-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <FileText className="h-4 w-4" />
                <span>Prosseguir para Pagamento</span>
              </button>
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-md w-full mx-4">
        {!termsAccepted && (
          <div className="mb-6">
            <div className="flex justify-between items-center">
              <h2 className="text-xl font-semibold text-gray-900 dark:text-white">
                Solicitar Crédito
              </h2>
              <button
                onClick={onClose}
                className="text-gray-500 hover:text-gray-700 dark:text-gray-400"
              >
                ×
              </button>
            </div>
            <div className="mt-2 flex justify-between">
              <div className="flex space-x-2">
                <div className={`w-3 h-3 rounded-full ${
                  step >= 1 ? 'bg-purple-600' : 'bg-gray-300 dark:bg-gray-600'
                }`} />
                <div className={`w-3 h-3 rounded-full ${
                  step >= 2 ? 'bg-purple-600' : 'bg-gray-300 dark:bg-gray-600'
                }`} />
                <div className={`w-3 h-3 rounded-full ${
                  step >= 3 ? 'bg-purple-600' : 'bg-gray-300 dark:bg-gray-600'
                }`} />
              </div>
              <span className="text-sm text-gray-500 dark:text-gray-400">
                Passo {step} de 3
              </span>
            </div>
          </div>
        )}

        {renderStepContent()}
      </div>
    </div>
  );
};

export default CreditRequestForm;