import React, { useState, useEffect } from 'react';
import { useUser } from '../../contexts/UserContext';
import { Shield, AlertTriangle, CheckCircle } from 'lucide-react';
import LoadingAnimation from './LoadingAnimation';
import CreditRequestForm from './CreditRequestForm';
import { checkCreditStatus } from '../../services/credit';
import Logo from '../Logo';

const CreditAnalysis = () => {
  const { userData } = useUser();
  const [loading, setLoading] = useState(true);
  const [creditStatus, setCreditStatus] = useState<'clean' | 'dirty' | null>(null);
  const [score, setScore] = useState<number | null>(null);
  const [showRequestForm, setShowRequestForm] = useState(false);

  useEffect(() => {
    const analyzeCreditStatus = async () => {
      try {
        setLoading(true);
        const result = await checkCreditStatus(userData?.cpf || '');
        setCreditStatus(result.status);
        setScore(result.score);
      } catch (error) {
        console.error('Error checking credit status:', error);
      } finally {
        setTimeout(() => setLoading(false), 2000);
      }
    };

    analyzeCreditStatus();
  }, [userData?.cpf]);

  return (
    <>
      <div className="max-w-4xl mx-auto px-4 py-8">
        <div className="mb-8 flex items-center justify-between">
          <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Análise de Crédito</h1>
          <Logo size="sm" />
        </div>

        <div className="bg-white dark:bg-gray-800 rounded-2xl shadow-sm overflow-hidden">
          {loading ? (
            <div className="p-8">
              <LoadingAnimation />
              <p className="text-center text-gray-600 dark:text-gray-400 mt-4">
                Analisando seu perfil de crédito...
              </p>
            </div>
          ) : (
            <div className="p-6">
              <div className="mb-6">
                <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
                  Informações Pessoais
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
                    <p className="text-sm text-gray-600 dark:text-gray-400">Nome Completo</p>
                    <p className="text-lg font-medium text-gray-900 dark:text-white">
                      {userData?.name}
                    </p>
                  </div>
                  <div className="p-4 bg-gray-50 dark:bg-gray-700 rounded-lg">
                    <p className="text-sm text-gray-600 dark:text-gray-400">CPF</p>
                    <p className="text-lg font-medium text-gray-900 dark:text-white">
                      {userData?.cpf}
                    </p>
                  </div>
                </div>
              </div>

              <div className="border-t border-gray-200 dark:border-gray-700 pt-6">
                <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
                  Resultado da Análise
                </h2>
                
                <div className={`p-6 rounded-lg ${
                  creditStatus === 'clean' 
                    ? 'bg-green-50 dark:bg-green-900/20' 
                    : 'bg-red-50 dark:bg-red-900/20'
                }`}>
                  <div className="flex items-center">
                    {creditStatus === 'clean' ? (
                      <CheckCircle className="h-8 w-8 text-green-500 dark:text-green-400" />
                    ) : (
                      <AlertTriangle className="h-8 w-8 text-red-500 dark:text-red-400" />
                    )}
                    <div className="ml-4">
                      <h3 className={`text-lg font-semibold ${
                        creditStatus === 'clean'
                          ? 'text-green-800 dark:text-green-200'
                          : 'text-red-800 dark:text-red-200'
                      }`}>
                        {creditStatus === 'clean' ? 'Nome Limpo' : 'Restrições Encontradas'}
                      </h3>
                      <p className={`mt-1 ${
                        creditStatus === 'clean'
                          ? 'text-green-700 dark:text-green-300'
                          : 'text-red-700 dark:text-red-300'
                      }`}>
                        {creditStatus === 'clean'
                          ? 'Seu histórico de crédito está em dia.'
                          : 'Existem pendências que precisam ser regularizadas.'}
                      </p>
                    </div>
                  </div>

                  {score !== null && (
                    <div className="mt-4 pt-4 border-t border-green-200 dark:border-green-800">
                      <div className="flex items-center justify-between">
                        <span className="text-sm text-gray-600 dark:text-gray-400">
                          Score de Crédito
                        </span>
                        <span className="text-2xl font-bold text-purple-600 dark:text-purple-400">
                          {score}
                        </span>
                      </div>
                      <div className="mt-2 h-2 bg-gray-200 dark:bg-gray-700 rounded-full">
                        <div 
                          className="h-2 bg-purple-600 rounded-full transition-all duration-1000"
                          style={{ width: `${(score / 1000) * 100}%` }}
                        />
                      </div>
                    </div>
                  )}
                </div>

                <button
                  onClick={() => setShowRequestForm(true)}
                  className="mt-6 w-full bg-purple-600 text-white px-6 py-3 rounded-lg hover:bg-purple-700 transition-colors"
                >
                  {creditStatus === 'clean' ? 'Solicitar Crédito' : 'Solicitar Regularização'}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {showRequestForm && (
        <CreditRequestForm onClose={() => setShowRequestForm(false)} />
      )}
    </>
  );
};

export default CreditAnalysis;