import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import DashboardLayout from '../dashboard/DashboardLayout';
import { useUser } from '../../contexts/UserContext';
import { getUserData } from '../../services/user';
import AffiliateDashboard from '../affiliate/AffiliateDashboard';
import InvestmentPlan from '../dashboard/InvestmentPlan';
import InvestmentOrders from '../dashboard/InvestmentOrders';

interface ViewedUserData {
  name: string;
  email: string;
  type: 'investor' | 'affiliate';
  status: 'active' | 'inactive';
}

const UserDashboardView = () => {
  const { userId } = useParams();
  const { userData: adminData } = useUser();
  const [viewedUser, setViewedUser] = useState<ViewedUserData | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchViewedUserData = async () => {
      if (!userId) return;
      
      try {
        setLoading(true);
        const userData = await getUserData(userId);
        if (userData) {
          setViewedUser(userData as ViewedUserData);
        }
      } catch (error) {
        console.error('Error fetching viewed user data:', error);
        navigate('/admin'); // Redirect to admin dashboard on error
      } finally {
        setLoading(false);
      }
    };

    fetchViewedUserData();
  }, [userId, navigate]);

  if (loading) {
    return (
      <DashboardLayout>
        <div className="flex items-center justify-center min-h-screen">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
        </div>
      </DashboardLayout>
    );
  }

  if (!viewedUser) {
    return (
      <DashboardLayout>
        <div className="text-center p-8">
          <p className="text-red-600 dark:text-red-400">Usuário não encontrado</p>
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      {/* Admin Banner */}
      <div className="fixed top-0 left-0 right-0 bg-yellow-100 dark:bg-yellow-900/50 p-2 text-center z-50">
        <p className="text-yellow-800 dark:text-yellow-200 text-sm">
          Visualizando dashboard de {viewedUser.name} ({viewedUser.email}) como administrador ({adminData?.email})
        </p>
      </div>
      
      {/* User Dashboard Content */}
      <div className="pt-12">
        {viewedUser.type === 'affiliate' ? (
          <AffiliateDashboard adminView={true} viewedUserId={userId} />
        ) : (
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="mb-8">
              <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
                Dashboard de {viewedUser.name}
              </h1>
              <p className="text-gray-600 dark:text-gray-400">
                Status: {viewedUser.status === 'active' ? 'Ativo' : 'Inativo'}
              </p>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
              <InvestmentPlan 
                userName={viewedUser.name}
                onOrderCreated={() => {}}
                adminView={true}
              />
            </div>

            <InvestmentOrders 
              userId={userId} 
              adminView={true}
            />
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default UserDashboardView;