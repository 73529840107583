import React, { useState, useEffect } from 'react';
import { Calendar, TrendingUp } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { createInvestmentOrder } from '../../services/investment';
import { calculateInvestmentReturn } from '../../utils/investment';
import PaymentModal from './PaymentModal';
import { useUser } from '../../contexts/UserContext';

interface InvestmentPlanProps {
  userName: string;
  onOrderCreated: () => void;
  adminView?: boolean;
}

const InvestmentPlan: React.FC<InvestmentPlanProps> = ({
  userName,
  onOrderCreated,
  adminView = false,
}) => {
  const [investmentAmount, setInvestmentAmount] = useState<string>('50');
  const [lockPeriod, setLockPeriod] = useState<string>('30');
  const { userData } = useUser();
  const [loading, setLoading] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [qrCode, setQrCode] = useState<string>(''); // QR Code gerado
  const [conciliationId, setConciliationId] = useState<string>(''); // Salvar o conciliationId
  const [paymentStatus, setPaymentStatus] = useState<string>('Pendente'); // Status do pagamento
  const { currentUser } = useAuth();

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (Number(value) >= 50 || value === '') {
      setInvestmentAmount(value);
    }
  };

  const formatCPF = (value: string) => {
    return value
      .replace(/\D/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1');
  };

  const handlePaymentStart = async () => {
    if (!currentUser || adminView) return;

    try {
      setLoading(true);

      // Gerar o idTransacao no formato AQBKXXXX
      const generateTransactionId = () => {
        const randomSequence = Math.floor(1000 + Math.random() * 9000); // Gera 4 números aleatórios
        return `AQBK${randomSequence}`; // Formato final
      };
      const idTransacao = generateTransactionId();

      const cpf = userData?.cpf ? userData.cpf.replace(/\D/g, '') : null; // Remove formatações apenas se o CPF existir

      // Enviar ao endpoint para gerar o QR Code
      const response = await fetch('https://tech.hkbank.digital/api/v1/regisbk', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          method: 'newdeposithk', // Método especificado
          uid: currentUser.uid,
          cpf, // CPF formatado com apenas números
          amount: Number(investmentAmount),
          lockPeriod: Number(lockPeriod),
          idTransacao, // Inclui o idTransacao no payload
        }),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Erro ao gerar QR Code.');
      }

      const data = await response.json();
      setQrCode(data.qrCode); // Salva o QR Code retornado
      setConciliationId(data.conciliationId); // Salva o conciliationId retornado
      setPaymentStatus('Pendente'); // Define o status inicial como "Pendente"
      setShowPaymentModal(true);
    } catch (error) {
      console.error('Erro ao iniciar o pagamento:', error);
    } finally {
      setLoading(false);
    }
  };

  // Função para verificar o status do pagamento diretamente do backend
  const checkPaymentStatus = async () => {
    if (!conciliationId) return;

    try {
      const response = await fetch(`https://tech.hkbank.digital/api/v1/check-payment/${conciliationId}`, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error('Erro ao verificar status do pagamento.');
      }

      const data = await response.json();
      if (data.status === 'success') {
        setPaymentStatus('Pago');
        await createInvestmentOrder(
          currentUser.uid,
          Number(investmentAmount),
          Number(lockPeriod),
          conciliationId // Inclui o conciliationId na ordem
        );
        onOrderCreated();
      } else {
        setPaymentStatus('Pendente');
      }
    } catch (error) {
      console.error('Erro ao verificar status do pagamento:', error);
    }
  };

  // Verifica o status do pagamento a cada 5 segundos
  useEffect(() => {
    if (paymentStatus === 'Pendente') {
      const interval = setInterval(checkPaymentStatus, 5000);
      return () => clearInterval(interval);
    }
  }, [conciliationId, paymentStatus]);

  return (
    <div className="bg-white dark:bg-gray-800 rounded-2xl p-4 sm:p-6 shadow-sm">
      <h2 className="text-lg sm:text-xl font-semibold mb-4 text-gray-900 dark:text-white">
        {adminView ? 'Plano de Investimento' : 'Aderir Plano'}
      </h2>
      <div className="bg-purple-600 rounded-xl p-4 sm:p-6 text-white mb-6">
        <div className="flex justify-between items-start mb-4">
          <div className="space-y-2">
            <p className="text-sm opacity-80">{userName}</p>
            <div className="flex items-center space-x-2">
              <TrendingUp className="h-5 w-5" />
              <span className="text-sm">Rendimento: 10% a.m.</span>
            </div>
          </div>
          <Calendar className="h-6 w-6 opacity-80" />
        </div>
      </div>

      <div className="space-y-4 mb-6">
        <div>
          <label
            htmlFor="amount"
            className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
          >
            Valor do Investimento (Mínimo R$ 50,00)
          </label>
          <div className="relative">
            <span className="absolute left-3 top-3 text-gray-500 dark:text-gray-400">R$</span>
            <input
              type="number"
              id="amount"
              min="50"
              value={investmentAmount}
              onChange={handleAmountChange}
              disabled={adminView}
              className="pl-10 block w-full rounded-lg border-1 py-2 border-gray-300 dark:border-gray-600 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:text-white disabled:opacity-50 disabled:cursor-not-allowed"
            />
          </div>
        </div>

        <div>
          <label
            htmlFor="period"
            className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1"
          >
            Período de Carência
          </label>
          <select
            id="period"
            value={lockPeriod}
            onChange={(e) => setLockPeriod(e.target.value)}
            disabled={adminView}
            className="block w-full rounded-lg border-gray-300 dark:border-gray-600 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:text-white disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <option value="30">30 dias</option>
            <option value="60">60 dias</option>
            <option value="90">90 dias</option>
            <option value="180">180 dias</option>
            <option value="360">360 dias</option>
          </select>
        </div>
      </div>

      {!adminView && (
        <div className="space-y-4">
          <button
            onClick={handlePaymentStart}
            disabled={loading}
            className="w-full bg-purple-600 text-white px-4 py-2 rounded-lg hover:bg-purple-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loading ? 'Processando...' : 'Iniciar Pagamento'}
          </button>

          <div className="text-sm text-gray-600 dark:text-gray-400">
            Status do pagamento: <strong>{paymentStatus}</strong>
          </div>
        </div>
      )}

      {showPaymentModal && (
        <PaymentModal
          amount={Number(investmentAmount)}
          onClose={() => setShowPaymentModal(false)}
          qrCode={qrCode} // Passa o QR Code gerado
          conciliationId={conciliationId} // Passa o conciliationId para o modal
        />
      )}
    </div>
  );
};

export default InvestmentPlan;
