import {
  collection,
  addDoc,
  doc,
  updateDoc,
  onSnapshot,
  query,
  where,
  orderBy,
  serverTimestamp,
  getDoc,
  Timestamp,
  limit,
  deleteDoc
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage, db } from '../config/firebase';
import { v4 as uuidv4 } from 'uuid';
import type { Message, ChatInfo, Attachment } from '../types/chat';

export const createSupportChat = async (userId: string) => {
  try {
    // Get user data for the chat
    const userDoc = await getDoc(doc(db, 'users', userId));
    const userData = userDoc.data();

    const chatRef = await addDoc(collection(db, 'support'), {
      userId,
      userName: userData?.name || 'Usuário',
      status: 'active',
      unreadCount: 0,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      lastMessage: 'Iniciou a conversa',
      sortOrder: Date.now(),
      userEmail: userData?.email || '',
      userPhone: userData?.phone || '',
      priority: 'normal',
      category: 'general',
      assignedTo: null,
      archived: false
    });

    // Add initial system message
    const messagesRef = collection(db, 'support', chatRef.id, 'messages');
    await addDoc(messagesRef, {
      content: 'Olá! Como posso ajudar você hoje?',
      sender: 'system',
      timestamp: serverTimestamp(),
      createdAt: serverTimestamp()
    });

    return { id: chatRef.id };
  } catch (error) {
    console.error('Error creating support chat:', error);
    throw error;
  }
};

export const sendMessage = async (chatId: string, message: Omit<Message, 'id'>) => {
  try {
    const messagesRef = collection(db, 'support', chatId, 'messages');
    await addDoc(messagesRef, {
      content: message.content,
      sender: message.sender,
      timestamp: serverTimestamp(),
      createdAt: serverTimestamp(),
      attachments: message.attachments || []
    });

    await updateDoc(doc(db, 'support', chatId), {
      lastMessage: message.content,
      updatedAt: serverTimestamp(),
      unreadCount: message.sender === 'user' ? 1 : 0,
      sortOrder: Date.now()
    });
  } catch (error) {
    console.error('Error sending message:', error);
    throw error;
  }
};

export const uploadAttachment = async (file: File): Promise<Attachment> => {
  try {
    const fileId = uuidv4();
    const fileExtension = file.name.split('.').pop();
    const fileName = `${fileId}.${fileExtension}`;
    const storageRef = ref(storage, `chat-attachments/${fileName}`);
    
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    
    return {
      id: fileId,
      name: file.name,
      url: downloadURL,
      type: file.type,
      size: file.size
    };
  } catch (error) {
    console.error('Error uploading attachment:', error);
    throw error;
  }
};

export const subscribeToMessages = (chatId: string, callback: (messages: Message[]) => void) => {
  const messagesRef = collection(db, 'support', chatId, 'messages');
  const q = query(messagesRef, orderBy('timestamp', 'asc'));

  return onSnapshot(q, (snapshot) => {
    const messages = snapshot.docs.map(doc => {
      const data = doc.data();
      const timestamp = data.timestamp instanceof Timestamp 
        ? data.timestamp.toDate().toISOString()
        : new Date().toISOString();
      
      return {
        id: doc.id,
        content: data.content,
        sender: data.sender,
        timestamp,
        attachments: data.attachments || []
      };
    });
    callback(messages);
  });
};

export const getActiveChats = (callback: (chats: ChatInfo[]) => void) => {
  const supportRef = collection(db, 'support');
  const q = query(
    supportRef,
    where('archived', '==', false),
    where('status', 'in', ['active', 'urgent']),
    orderBy('sortOrder', 'desc'),
    limit(50)
  );
  
  return onSnapshot(q, (snapshot) => {
    const chats = snapshot.docs.map(doc => {
      const data = doc.data();
      return {
        id: doc.id,
        ...data,
        createdAt: data.createdAt instanceof Timestamp 
          ? data.createdAt.toDate().toISOString() 
          : new Date().toISOString(),
        updatedAt: data.updatedAt instanceof Timestamp 
          ? data.updatedAt.toDate().toISOString() 
          : new Date().toISOString()
      } as ChatInfo;
    });
    callback(chats);
  });
};

export const getArchivedChats = (callback: (chats: ChatInfo[]) => void) => {
  const supportRef = collection(db, 'support');
  const q = query(
    supportRef,
    where('archived', '==', true),
    orderBy('resolvedAt', 'desc'),
    limit(50)
  );
  
  return onSnapshot(q, (snapshot) => {
    const chats = snapshot.docs.map(doc => {
      const data = doc.data();
      return {
        id: doc.id,
        ...data,
        createdAt: data.createdAt instanceof Timestamp 
          ? data.createdAt.toDate().toISOString() 
          : new Date().toISOString(),
        updatedAt: data.updatedAt instanceof Timestamp 
          ? data.updatedAt.toDate().toISOString() 
          : new Date().toISOString(),
        resolvedAt: data.resolvedAt instanceof Timestamp
          ? data.resolvedAt.toDate().toISOString()
          : data.resolvedAt
      } as ChatInfo;
    });
    callback(chats);
  });
};

export const markChatAsRead = async (chatId: string) => {
  try {
    await updateDoc(doc(db, 'support', chatId), {
      unreadCount: 0,
      updatedAt: serverTimestamp(),
      sortOrder: Date.now()
    });
  } catch (error) {
    console.error('Error marking chat as read:', error);
    throw error;
  }
};

export const finalizeChat = async (chatId: string) => {
  try {
    await updateDoc(doc(db, 'support', chatId), {
      archived: true,
      status: 'resolved',
      resolvedAt: serverTimestamp(),
      updatedAt: serverTimestamp()
    });
  } catch (error) {
    console.error('Error finalizing chat:', error);
    throw error;
  }
};

export const reopenChat = async (chatId: string) => {
  try {
    await updateDoc(doc(db, 'support', chatId), {
      archived: false,
      status: 'active',
      resolvedAt: null,
      updatedAt: serverTimestamp(),
      sortOrder: Date.now()
    });
  } catch (error) {
    console.error('Error reopening chat:', error);
    throw error;
  }
};