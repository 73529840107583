import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  LayoutDashboard, 
  Wallet, 
  Settings, 
  LogOut,
  ChevronLeft,
  ChevronRight,
  Menu,
  Users
} from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

interface AffiliateSidebarProps {
  adminView?: boolean;
}

const AffiliateSidebar: React.FC<AffiliateSidebarProps> = ({ adminView = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const menuItems = [
    {
      title: 'Dashboard',
      icon: LayoutDashboard,
      path: '/affiliate/dashboard'
    },
    {
      title: 'Gerenciamento',
      icon: Users,
      path: '/affiliate/management'
    },
    {
      title: 'Financeiro',
      icon: Wallet,
      path: '/affiliate/finance'
    },
    {
      title: 'Configurações',
      icon: Settings,
      path: '/affiliate/settings'
    }
  ];

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const isActive = (path: string) => location.pathname === path;

  return (
    <div 
      className={`${
        isCollapsed ? 'w-20' : 'w-64'
      } bg-white dark:bg-gray-800 h-screen shadow-sm flex flex-col relative transition-all duration-300`}
    >
      {/* Toggle Button */}
      <button
        onClick={() => setIsCollapsed(!isCollapsed)}
        className="absolute -right-3 top-6 bg-white dark:bg-gray-800 p-1.5 rounded-full border border-gray-200 dark:border-gray-700 shadow-sm hover:bg-gray-50 dark:hover:bg-gray-700"
      >
        {isCollapsed ? (
          <ChevronRight className="h-4 w-4 text-gray-600 dark:text-gray-400" />
        ) : (
          <ChevronLeft className="h-4 w-4 text-gray-600 dark:text-gray-400" />
        )}
      </button>

      <div className="p-4">
        <div className="flex items-center justify-between mb-8">
          {isCollapsed ? (
            <Menu className="h-6 w-6 text-gray-600 dark:text-gray-400 mx-auto" />
          ) : (
            <div>
              <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
                Área do Afiliado
              </h2>
              <p className="text-sm text-gray-500 dark:text-gray-400">
                Gerenciamento
              </p>
            </div>
          )}
        </div>

        <nav className="space-y-1">
          {menuItems.map((item) => {
            const Icon = item.icon;
            const active = isActive(item.path);
            
            return (
              <button
                key={item.path}
                onClick={() => navigate(item.path)}
                className={`w-full flex items-center ${
                  isCollapsed ? 'justify-center' : 'justify-start space-x-3'
                } px-4 py-2 rounded-lg transition-colors ${
                  active 
                    ? 'bg-purple-600 text-white'
                    : 'text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
                } ${adminView ? 'pointer-events-none opacity-50' : ''}`}
                title={isCollapsed ? item.title : undefined}
              >
                <Icon className="h-5 w-5" />
                {!isCollapsed && <span>{item.title}</span>}
              </button>
            );
          })}
        </nav>
      </div>

      {!adminView && (
        <div className="mt-auto p-4 border-t border-gray-200 dark:border-gray-700">
          <button
            onClick={handleLogout}
            className={`flex items-center ${
              isCollapsed ? 'justify-center' : 'justify-start space-x-2'
            } text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white w-full`}
            title={isCollapsed ? 'Sair' : undefined}
          >
            <LogOut className="h-5 w-5" />
            {!isCollapsed && <span>Sair</span>}
          </button>
        </div>
      )}
    </div>
  );
};

export default AffiliateSidebar;