import { 
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut as firebaseSignOut,
  UserCredential
} from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { auth, db } from '../config/firebase';
import type { RegisterFormData, LoginFormData } from '../types/auth';

export const registerUser = async (data: RegisterFormData): Promise<UserCredential> => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, data.email, data.password);
    
    // Store additional user data in Firestore
    await setDoc(doc(db, 'users', userCredential.user.uid), {
      name: data.name,
      email: data.email,
      phone: data.phone,
      country: data.country,
      // Only store these fields if country is Brazil
      ...(data.country === 'BR' && {
        cpf: data.cpf,
        birthDate: data.birthDate,
        motherName: data.motherName
      }),
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      status: 'active',
      type: 'investor',
      isAdmin: false,
      isAffiliate: false,
      affiliateStatus: null,
      investmentAmount: 0,
      hasCreditPlan: false
    });

    return userCredential;
  } catch (error) {
    console.error('Error registering user:', error);
    throw error;
  }
};

export const loginUser = async (data: LoginFormData): Promise<UserCredential> => {
  try {
    return await signInWithEmailAndPassword(auth, data.email, data.password);
  } catch (error) {
    console.error('Error logging in:', error);
    throw error;
  }
};

export const signOut = async (): Promise<void> => {
  try {
    await firebaseSignOut(auth);
  } catch (error) {
    console.error('Error signing out:', error);
    throw error;
  }
};