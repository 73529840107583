import React, { useState, useEffect } from 'react';
import { TrendingUp, TrendingDown } from 'lucide-react';

interface Currency {
  pair: string;
  price: string;
  change: number;
  previousPrice?: string;
}

const CurrencyTicker: React.FC = () => {
  const [currencies, setCurrencies] = useState<Currency[]>([
    { pair: 'BTC/USD', price: '43,250.00', change: 2.5 },
    { pair: 'ETH/USD', price: '2,280.50', change: -1.2 },
    { pair: 'EUR/USD', price: '1.0950', change: 0.3 },
    { pair: 'GBP/USD', price: '1.2680', change: -0.4 },
    { pair: 'USD/JPY', price: '148.20', change: 0.1 },
    { pair: 'XRP/USD', price: '0.5240', change: 1.8 },
    { pair: 'SOL/USD', price: '98.45', change: 3.2 },
    { pair: 'ADA/USD', price: '0.4850', change: -0.7 }
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrencies(prev => prev.map(currency => {
        const priceChange = (Math.random() * 0.02 - 0.01) * parseFloat(currency.price.replace(',', ''));
        const newPrice = (parseFloat(currency.price.replace(',', '')) + priceChange).toFixed(
          currency.price.includes('.') ? currency.price.split('.')[1].length : 2
        );
        
        return {
          ...currency,
          previousPrice: currency.price,
          price: Number(newPrice).toLocaleString('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 4
          }),
          change: Number((Math.random() * 6 - 3).toFixed(2))
        };
      }));
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="w-full bg-black dark:bg-gray-900 border-y border-gray-800">
      <div className="relative overflow-hidden">
        <div className="animate-ticker flex whitespace-nowrap py-2">
          {[...currencies, ...currencies].map((currency, index) => (
            <div
              key={`${currency.pair}-${index}`}
              className="inline-flex items-center space-x-2 px-4 font-mono"
            >
              <span className="text-sm font-bold text-gray-300">
                {currency.pair}
              </span>
              <span className={`text-sm transition-colors duration-300 ${
                currency.previousPrice && parseFloat(currency.price) > parseFloat(currency.previousPrice)
                  ? 'text-green-400'
                  : currency.previousPrice && parseFloat(currency.price) < parseFloat(currency.previousPrice)
                  ? 'text-red-400'
                  : 'text-yellow-400'
              }`}>
                {currency.price}
              </span>
              <div className={`flex items-center ${
                currency.change >= 0 
                  ? 'text-green-400' 
                  : 'text-red-400'
              }`}>
                {currency.change >= 0 
                  ? <TrendingUp className="h-3 w-3" /> 
                  : <TrendingDown className="h-3 w-3" />
                }
                <span className="text-xs ml-1">
                  {Math.abs(currency.change)}%
                </span>
              </div>
              <span className="text-gray-600 mx-4">|</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CurrencyTicker;