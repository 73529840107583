import React, { useState } from 'react';
import { ArrowDownLeft, Copy, Check, X } from 'lucide-react';
import { useUser } from '../../contexts/UserContext';

interface WithdrawalModalProps {
  amount: number;
  onClose: () => void;
  onSuccess: () => void;
}

const WithdrawalModal: React.FC<WithdrawalModalProps> = ({ amount, onClose, onSuccess }) => {
  const { userData } = useUser();
  const [copied, setCopied] = useState(false);
  const [withdrawalAmount, setWithdrawalAmount] = useState(amount.toString());
  const [error, setError] = useState('');
  const MIN_WITHDRAWAL = 50;

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (Number(value) <= amount) {
      setWithdrawalAmount(value);
      setError('');
    } else {
      setError('O valor não pode exceder o lucro disponível');
    }
  };

  const handleWithdrawal = () => {
    const numAmount = Number(withdrawalAmount);
    if (numAmount <= 0) {
      setError('O valor deve ser maior que zero');
      return;
    }
    if (numAmount > amount) {
      setError('O valor não pode exceder o lucro disponível');
      return;
    }
    if (numAmount < MIN_WITHDRAWAL) {
      setError(`O valor mínimo para saque é de R$ ${MIN_WITHDRAWAL.toFixed(2)}`);
      return;
    }
    onSuccess();
  };

  const isValidAmount = Number(withdrawalAmount) >= MIN_WITHDRAWAL;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 max-w-md w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
            Solicitar Saque
          </h3>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        <div className="space-y-4">
          <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
            <p className="text-sm text-gray-600 dark:text-gray-400 mb-2">
              Lucro Disponível
            </p>
            <p className="text-2xl font-bold text-gray-900 dark:text-white">
              {amount.toLocaleString('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              })}
            </p>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">
              Valor do Saque
            </label>
            <div className="relative">
              <span className="absolute left-3 top-2 text-gray-500 dark:text-gray-400">R$</span>
              <input
                type="number"
                value={withdrawalAmount}
                onChange={handleAmountChange}
                className={`pl-10 block w-full rounded-lg border-gray-300 dark:border-gray-600 focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 ${
                  isValidAmount ? 'text-gray-900 dark:text-white' : 'text-red-600 dark:text-red-400'
                }`}
              />
            </div>
            {!isValidAmount && (
              <p className="mt-1 text-sm text-red-600 dark:text-red-400">
                O valor mínimo para saque é de R$ {MIN_WITHDRAWAL.toFixed(2)}
              </p>
            )}
            {error && (
              <p className="mt-1 text-sm text-red-600 dark:text-red-400">{error}</p>
            )}
          </div>

          <div className="bg-purple-50 dark:bg-purple-900/20 p-4 rounded-lg">
            <h4 className="font-medium text-gray-900 dark:text-white mb-2">
              Informações da Conta
            </h4>
            <div className="space-y-2 text-sm text-gray-600 dark:text-gray-400">
              <p>Nome: {userData?.name}</p>
              <p>CPF: {userData?.cpf}</p>
              <p>Email: {userData?.email}</p>
            </div>
          </div>

          <div className="flex justify-end space-x-3">
            <button
              onClick={onClose}
              className="px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700 rounded-lg"
            >
              Cancelar
            </button>
            <button
              onClick={handleWithdrawal}
              disabled={!isValidAmount}
              className="px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-700 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Confirmar Saque
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawalModal;