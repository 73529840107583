import React from 'react';
import { Shield, Smartphone, Clock, PiggyBank } from 'lucide-react';
import type { FeatureType } from '../types';

const features: FeatureType[] = [
  {
    id: 1,
    title: 'Investimento Seguro',
    description: 'Invista com confiança usando nossos sistemas de segurança e criptografia de última geração.',
    icon: 'Shield'
  },
  {
    id: 2,
    title: 'Plataforma Digital',
    description: 'Acesse seus investimentos a qualquer hora, em qualquer lugar, com nossa plataforma premiada.',
    icon: 'Smartphone'
  },
  {
    id: 3,
    title: 'Suporte 24/7',
    description: 'Obtenha ajuda sempre que precisar com nosso atendimento ao cliente disponível 24 horas.',
    icon: 'Clock'
  },
  {
    id: 4,
    title: 'Rendimentos Inteligentes',
    description: 'Faça seu dinheiro trabalhar para você com nossos planos de investimento em energia solar.',
    icon: 'PiggyBank'
  }
];

const IconComponent = ({ name }: { name: string }) => {
  const icons = {
    Shield: Shield,
    Smartphone: Smartphone,
    Clock: Clock,
    PiggyBank: PiggyBank
  };
  const Icon = icons[name as keyof typeof icons];
  return <Icon className="h-6 w-6 text-purple-600" />;
};

const Features = () => {
  return (
    <section id="features" className="py-20 bg-white dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white sm:text-4xl">
            Por que escolher o HK Bank?
          </h2>
          <p className="mt-4 text-lg text-gray-600 dark:text-gray-400">
            Oferecemos soluções modernas de investimento com foco em segurança e rentabilidade.
          </p>
        </div>

        <div className="mt-20 grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-4">
          {features.map((feature) => (
            <div
              key={feature.id}
              className="relative p-6 bg-white dark:bg-gray-800 rounded-xl shadow-sm hover:shadow-md transition-shadow duration-300"
            >
              <div className="w-12 h-12 flex items-center justify-center rounded-lg bg-purple-100 dark:bg-purple-900/50">
                <IconComponent name={feature.icon} />
              </div>
              <h3 className="mt-4 text-xl font-semibold text-gray-900 dark:text-white">
                {feature.title}
              </h3>
              <p className="mt-2 text-gray-600 dark:text-gray-400">
                {feature.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;