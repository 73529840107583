interface CreditCheckResult {
  status: 'clean' | 'dirty';
  score: number;
}

export const checkCreditStatus = async (cpf: string): Promise<CreditCheckResult> => {
  // Simulate API call to credit check service
  return new Promise((resolve) => {
    setTimeout(() => {
      // For demo purposes, generate random result
      const score = Math.floor(Math.random() * 1000);
      resolve({
        status: score > 500 ? 'clean' : 'dirty',
        score
      });
    }, 1500);
  });
};