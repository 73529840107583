import React from 'react';
import { Check, X, DollarSign } from 'lucide-react';
import { formatCurrency } from '../../utils/format';

const WithdrawalRequests = () => {
  const withdrawals: any[] = []; // Empty array instead of mock data

  const handleApprove = async (id: number) => {
    // Implement real approval logic
    console.log('Approved withdrawal:', id);
  };

  const handleReject = async (id: number) => {
    // Implement real rejection logic
    console.log('Rejected withdrawal:', id);
  };

  return (
    <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm">
      <div className="p-6">
        <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
          Saques Pendentes
        </h2>
        <div className="space-y-4">
          {withdrawals.length === 0 ? (
            <div className="text-center text-gray-500 dark:text-gray-400 py-8">
              Nenhum saque pendente
            </div>
          ) : (
            withdrawals.map((withdrawal) => (
              <div key={withdrawal.id} className="flex items-center justify-between p-4 bg-gray-50 dark:bg-gray-700/50 rounded-lg">
                <div className="flex items-center">
                  <div className="p-2 bg-green-100 dark:bg-green-900/50 rounded-full">
                    <DollarSign className="h-5 w-5 text-green-600 dark:text-green-400" />
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-gray-900 dark:text-white">
                      {withdrawal.user}
                    </p>
                    <p className="text-sm text-gray-500 dark:text-gray-400">
                      {formatCurrency(withdrawal.amount)}
                    </p>
                  </div>
                </div>
                <div className="flex space-x-2">
                  <button
                    onClick={() => handleApprove(withdrawal.id)}
                    className="p-1 bg-green-100 text-green-600 rounded-full hover:bg-green-200 dark:bg-green-900/50 dark:text-green-400"
                  >
                    <Check className="h-5 w-5" />
                  </button>
                  <button
                    onClick={() => handleReject(withdrawal.id)}
                    className="p-1 bg-red-100 text-red-600 rounded-full hover:bg-red-200 dark:bg-red-900/50 dark:text-red-400"
                  >
                    <X className="h-5 w-5" />
                  </button>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default WithdrawalRequests;