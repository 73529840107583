import React, { useState } from 'react';
import { MessageSquare, Users, Clock, CheckCircle, Archive } from 'lucide-react';
import ChatList from './ChatList';
import ChatWindow from './ChatWindow';
import UserInfo from './UserInfo';
import ArchivedChats from './ArchivedChats';

const SupportDashboard = () => {
  const [selectedChat, setSelectedChat] = useState<string | null>(null);
  const [showArchived, setShowArchived] = useState(false);

  return (
    <div className="p-6">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          Suporte ao Cliente
        </h1>
        <p className="mt-2 text-gray-600 dark:text-gray-400">
          Gerencie as solicitações de suporte dos clientes
        </p>
      </div>

      {/* Stats Overview */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-8">
        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-sm">
          <div className="flex items-center">
            <div className="p-2 bg-purple-100 dark:bg-purple-900/50 rounded-lg">
              <MessageSquare className="h-6 w-6 text-purple-600 dark:text-purple-400" />
            </div>
            <div className="ml-4">
              <p className="text-sm text-gray-600 dark:text-gray-400">Chats Ativos</p>
              <p className="text-2xl font-semibold text-gray-900 dark:text-white">24</p>
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-sm">
          <div className="flex items-center">
            <div className="p-2 bg-blue-100 dark:bg-blue-900/50 rounded-lg">
              <Users className="h-6 w-6 text-blue-600 dark:text-blue-400" />
            </div>
            <div className="ml-4">
              <p className="text-sm text-gray-600 dark:text-gray-400">Em Atendimento</p>
              <p className="text-2xl font-semibold text-gray-900 dark:text-white">8</p>
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-sm">
          <div className="flex items-center">
            <div className="p-2 bg-yellow-100 dark:bg-yellow-900/50 rounded-lg">
              <Clock className="h-6 w-6 text-yellow-600 dark:text-yellow-400" />
            </div>
            <div className="ml-4">
              <p className="text-sm text-gray-600 dark:text-gray-400">Tempo Médio</p>
              <p className="text-2xl font-semibold text-gray-900 dark:text-white">15min</p>
            </div>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-sm">
          <div className="flex items-center">
            <div className="p-2 bg-green-100 dark:bg-green-900/50 rounded-lg">
              <CheckCircle className="h-6 w-6 text-green-600 dark:text-green-400" />
            </div>
            <div className="ml-4">
              <p className="text-sm text-gray-600 dark:text-gray-400">Resolvidos Hoje</p>
              <p className="text-2xl font-semibold text-gray-900 dark:text-white">42</p>
            </div>
          </div>
        </div>
      </div>

      {/* View Toggle */}
      <div className="mb-6 flex justify-end">
        <button
          onClick={() => setShowArchived(!showArchived)}
          className={`flex items-center space-x-2 px-4 py-2 rounded-lg transition-colors ${
            showArchived
              ? 'bg-purple-600 text-white'
              : 'bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-300'
          }`}
        >
          <Archive className="h-5 w-5" />
          <span>{showArchived ? 'Ver Chats Ativos' : 'Ver Chats Arquivados'}</span>
        </button>
      </div>

      {/* Main Content */}
      <div className="grid grid-cols-12 gap-6">
        <div className="col-span-12 lg:col-span-3">
          {showArchived ? (
            <ArchivedChats onSelectChat={setSelectedChat} selectedChat={selectedChat} />
          ) : (
            <ChatList onSelectChat={setSelectedChat} selectedChat={selectedChat} />
          )}
        </div>
        
        <div className="col-span-12 lg:col-span-6">
          <ChatWindow chatId={selectedChat} archived={showArchived} />
        </div>

        <div className="col-span-12 lg:col-span-3">
          <UserInfo chatId={selectedChat} />
        </div>
      </div>
    </div>
  );
};

export default SupportDashboard;