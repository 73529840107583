import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useUser } from '../../contexts/UserContext';
import { getAffiliateData } from '../../services/affiliate';
import AffiliateMetrics from './AffiliateMetrics';
import AffiliateChart from './AffiliateChart';
import ReferralLink from './ReferralLink';

interface AffiliateOverviewProps {
  adminView?: boolean;
  viewedUserId?: string;
}

const AffiliateOverview: React.FC<AffiliateOverviewProps> = ({ 
  adminView = false,
  viewedUserId 
}) => {
  const { currentUser } = useAuth();
  const { userData } = useUser();
  const [affiliateData, setAffiliateData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchAffiliateData = async () => {
      const userId = viewedUserId || currentUser?.uid;
      if (!userId) return;
      
      try {
        setLoading(true);
        const data = await getAffiliateData(userId);
        setAffiliateData(data);
      } catch (err) {
        console.error('Error fetching affiliate data:', err);
        setError('Erro ao carregar dados do afiliado');
      } finally {
        setLoading(false);
      }
    };

    fetchAffiliateData();
  }, [currentUser, viewedUserId]);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-6 text-center text-red-600 dark:text-red-400">
        {error}
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">
          {adminView ? 'Visão Geral do Afiliado' : 'Meu Painel de Afiliado'}
        </h1>
        <p className="mt-1 text-gray-600 dark:text-gray-400">
          {adminView 
            ? `Visualizando dados de ${userData?.name}`
            : 'Acompanhe seus resultados e indicações'
          }
        </p>
      </div>

      {/* Metrics */}
      <div className="mb-8">
        <AffiliateMetrics data={affiliateData?.metrics} />
      </div>

      {/* Performance Chart */}
      <div className="mb-8">
        <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm">
          <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
            Performance
          </h2>
          <AffiliateChart />
        </div>
      </div>

      {/* Referral Link */}
      <div className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm">
        <h2 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
          Link de Afiliado
        </h2>
        <ReferralLink adminView={adminView} />
      </div>
    </div>
  );
};

export default AffiliateOverview;