import React, { useState, useEffect } from 'react';
import { Sun, Menu } from 'lucide-react';
import { useUser } from '../../contexts/UserContext';
import { getTotalSolarPanels } from '../../services/solarPanels';
import Logo from '../Logo';
import Sidebar from './Sidebar';
import InvestmentPlan from './InvestmentPlan';
import InvestmentOrders from './InvestmentOrders';
import CurrencyTicker from '../CurrencyTicker';
import ThemeToggle from '../ThemeToggle';
import LanguageSelector from '../LanguageSelector';

interface DashboardLayoutProps {
  children?: React.ReactNode;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [refreshOrders, setRefreshOrders] = useState(0);
  const [solarPanelData, setSolarPanelData] = useState({
    totalPanels: 0,
    totalEnergy: 0,
    totalCO2Reduction: 0
  });
  const { userData } = useUser();
  const firstName = userData?.name?.split(' ')[0] || '';

  useEffect(() => {
    const fetchSolarPanelData = async () => {
      if (userData?.id) {
        try {
          const data = await getTotalSolarPanels(userData.id);
          setSolarPanelData(data);
        } catch (error) {
          console.error('Error fetching solar panel data:', error);
        }
      }
    };

    fetchSolarPanelData();
  }, [userData?.id]);

  const handleOrderCreated = () => {
    setRefreshOrders(prev => prev + 1);
  };

  const renderDefaultContent = () => {
    return (
      <>
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-8">
          <div>
            <h1 className="text-xl sm:text-2xl font-bold text-gray-900 dark:text-white">
              Olá, {firstName}
            </h1>
            <p className="text-sm sm:text-base text-gray-600 dark:text-gray-400">
              Bem vindo ao seu painel de investimentos.
            </p>
          </div>
          <div className="flex items-center gap-4">
            <LanguageSelector />
            <ThemeToggle />
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 sm:gap-6 lg:gap-8">
          <InvestmentPlan 
            userName={userData?.name || ''} 
            onOrderCreated={handleOrderCreated}
          />

          {/* Solar Panels Information */}
          <div className="bg-white dark:bg-gray-800 rounded-2xl p-4 sm:p-6 shadow-sm">
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6">
              <h2 className="text-lg sm:text-xl font-semibold text-gray-900 dark:text-white">
                Painéis Solares em Comodato
              </h2>
              <div className="flex items-center text-yellow-500">
                <Sun className="h-6 w-6 mr-2" />
                <span className="text-sm font-medium">Energia Limpa</span>
              </div>
            </div>
            
            <div className="space-y-6">
              <div className="bg-gray-50 dark:bg-gray-700/50 rounded-lg p-4">
                <p className="text-sm text-gray-600 dark:text-gray-400 mb-1">
                  Proporção do Painel
                </p>
                <p className="text-2xl font-bold text-purple-600 dark:text-purple-400">
                  {solarPanelData.totalPanels.toFixed(2)}%
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                  Cada R$ 2.000,00 = 100% de um painel
                </p>
              </div>

              <div className="bg-gray-50 dark:bg-gray-700/50 rounded-lg p-4">
                <p className="text-sm text-gray-600 dark:text-gray-400 mb-1">
                  Produção Estimada
                </p>
                <p className="text-2xl font-bold text-green-600 dark:text-green-400">
                  {solarPanelData.totalEnergy.toFixed(2)} kWh/mês
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                  R$ 1,00 = 0,02 kWh/mês
                </p>
              </div>

              <div className="bg-gray-50 dark:bg-gray-700/50 rounded-lg p-4">
                <p className="text-sm text-gray-600 dark:text-gray-400 mb-1">
                  Redução de CO₂
                </p>
                <p className="text-2xl font-bold text-blue-600 dark:text-blue-400">
                  {solarPanelData.totalCO2Reduction.toFixed(3)} kg/mês
                </p>
                <p className="text-xs text-gray-500 dark:text-gray-400 mt-1">
                  R$ 1,00 = 0,0017 kg CO₂/mês evitado
                </p>
              </div>
            </div>
          </div>
        </div>

        <InvestmentOrders refreshTrigger={refreshOrders} />
      </>
    );
  };

  return (
    <div className="flex h-screen bg-gray-50 dark:bg-gray-900">
      {/* Mobile sidebar backdrop */}
      {isSidebarOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-20 lg:hidden"
          onClick={() => setIsSidebarOpen(false)}
        />
      )}

      {/* Sidebar */}
      <div className={`
        fixed inset-y-0 left-0 transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}
        lg:relative lg:translate-x-0 transition duration-200 ease-in-out z-30
      `}>
        <Sidebar onClose={() => setIsSidebarOpen(false)} />
      </div>

      {/* Main content */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Mobile header */}
        <div className="lg:hidden bg-white dark:bg-gray-800 border-b border-gray-200 dark:border-gray-700 px-4 py-2">
          <div className="flex items-center justify-between">
            <button
              onClick={() => setIsSidebarOpen(true)}
              className="p-2 rounded-md hover:bg-gray-100 dark:hover:bg-gray-700"
            >
              <Menu className="h-6 w-6 text-gray-600 dark:text-gray-300" />
            </button>
            <div className="flex items-center space-x-4">
              <LanguageSelector />
              <ThemeToggle />
            </div>
          </div>
        </div>

        {/* Currency Ticker */}
        <CurrencyTicker />

        <div className="flex-1 overflow-auto">
          <div className="p-4 sm:p-6 lg:p-8">
            {children || renderDefaultContent()}
          </div>
        </div>

        {/* Footer */}
        <div className="mt-auto border-t border-gray-200 dark:border-gray-700">
          <div className="p-4 flex items-center justify-center">
            <Logo size="sm" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;