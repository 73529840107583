import React, { useState } from 'react';
import { Shield, Key, Lock, Smartphone } from 'lucide-react';
import DashboardLayout from '../dashboard/DashboardLayout';
import Logo from '../Logo';
import TwoFactorSetup from './TwoFactorSetup';
import PinSetup from './PinSetup';
import PasswordChange from './PasswordChange';

const SecurityContent = () => {
  const [activeSection, setActiveSection] = useState<'2fa' | 'pin' | 'password'>('2fa');

  return (
    <div className="max-w-4xl mx-auto px-4 py-8">
      <div className="mb-8 flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white">Segurança</h1>
        <Logo size="sm" />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
        {/* Security Navigation */}
        <div className="md:col-span-1">
          <nav className="space-y-1">
            <button
              onClick={() => setActiveSection('2fa')}
              className={`w-full flex items-center space-x-3 px-4 py-3 text-sm font-medium rounded-lg transition-colors ${
                activeSection === '2fa'
                  ? 'bg-purple-600 text-white'
                  : 'text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
              }`}
            >
              <Smartphone className="h-5 w-5" />
              <span>Autenticação 2FA</span>
            </button>

            <button
              onClick={() => setActiveSection('pin')}
              className={`w-full flex items-center space-x-3 px-4 py-3 text-sm font-medium rounded-lg transition-colors ${
                activeSection === 'pin'
                  ? 'bg-purple-600 text-white'
                  : 'text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
              }`}
            >
              <Key className="h-5 w-5" />
              <span>PIN de Segurança</span>
            </button>

            <button
              onClick={() => setActiveSection('password')}
              className={`w-full flex items-center space-x-3 px-4 py-3 text-sm font-medium rounded-lg transition-colors ${
                activeSection === 'password'
                  ? 'bg-purple-600 text-white'
                  : 'text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700'
              }`}
            >
              <Lock className="h-5 w-5" />
              <span>Alterar Senha</span>
            </button>
          </nav>
        </div>

        {/* Security Content */}
        <div className="md:col-span-3">
          <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm">
            {activeSection === '2fa' && <TwoFactorSetup />}
            {activeSection === 'pin' && <PinSetup />}
            {activeSection === 'password' && <PasswordChange />}
          </div>
        </div>
      </div>
    </div>
  );
};

const SecurityPage = () => {
  return (
    <DashboardLayout>
      <SecurityContent />
    </DashboardLayout>
  );
};

export default SecurityPage;