import React, { useState, useEffect } from 'react';
import { Users, Search, Filter, DollarSign, CreditCard, CheckCircle, XCircle, ChevronDown, ChevronUp } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { getAffiliateReferrals } from '../../services/affiliate';
import { formatCurrency } from '../../utils/format';

interface ReferralDetails {
  id: string;
  userId: string;
  name: string;
  email: string;
  level: 1 | 2 | 3;
  status: 'active' | 'inactive';
  investmentAmount: number;
  creditPlan: boolean;
  registrationDate: string;
  expanded?: boolean;
}

const AffiliateNetwork = () => {
  const { currentUser } = useAuth();
  const [referrals, setReferrals] = useState<ReferralDetails[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedLevel, setSelectedLevel] = useState<'all' | 1 | 2 | 3>('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedReferral, setExpandedReferral] = useState<string | null>(null);

  useEffect(() => {
    const fetchReferrals = async () => {
      if (!currentUser) return;
      
      try {
        setLoading(true);
        const data = await getAffiliateReferrals(currentUser.uid);
        setReferrals(data);
      } catch (error) {
        console.error('Error fetching referrals:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchReferrals();
  }, [currentUser]);

  const filteredReferrals = referrals.filter(referral => {
    const matchesSearch = 
      referral.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      referral.email.toLowerCase().includes(searchTerm.toLowerCase());
    
    const matchesLevel = selectedLevel === 'all' || referral.level === selectedLevel;

    return matchesSearch && matchesLevel;
  });

  const getLevelColor = (level: number) => {
    switch (level) {
      case 1: return 'text-blue-600 dark:text-blue-400 bg-blue-100 dark:bg-blue-900/20';
      case 2: return 'text-green-600 dark:text-green-400 bg-green-100 dark:bg-green-900/20';
      case 3: return 'text-purple-600 dark:text-purple-400 bg-purple-100 dark:bg-purple-900/20';
      default: return '';
    }
  };

  const getLevelCommission = (level: number) => {
    switch (level) {
      case 1: return '10%';
      case 2: return '5%';
      case 3: return '3%';
      default: return '0%';
    }
  };

  const toggleReferralDetails = (referralId: string) => {
    setExpandedReferral(expandedReferral === referralId ? null : referralId);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="mb-8">
        <h1 className="text-2xl font-bold text-gray-900 dark:text-white mb-2">Minha Rede</h1>
        <p className="text-gray-600 dark:text-gray-400">
          Gerencie seus indicados e acompanhe seus investimentos
        </p>
      </div>

      {/* Network Stats */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        {[1, 2, 3].map((level) => {
          const levelReferrals = referrals.filter(r => r.level === level);
          const totalInvestment = levelReferrals.reduce((sum, r) => sum + r.investmentAmount, 0);
          
          return (
            <div key={level} className="bg-white dark:bg-gray-800 rounded-lg p-6 shadow-sm">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  Nível {level}
                </h3>
                <span className={`px-3 py-1 rounded-full text-sm font-medium ${getLevelColor(level)}`}>
                  {getLevelCommission(level)}
                </span>
              </div>
              <div className="space-y-2">
                <div className="flex justify-between">
                  <span className="text-gray-600 dark:text-gray-400">Indicados:</span>
                  <span className="font-medium text-gray-900 dark:text-white">{levelReferrals.length}</span>
                </div>
                <div className="flex justify-between">
                  <span className="text-gray-600 dark:text-gray-400">Total Investido:</span>
                  <span className="font-medium text-gray-900 dark:text-white">{formatCurrency(totalInvestment)}</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* Filters */}
      <div className="mb-6 flex flex-col sm:flex-row gap-4">
        <div className="flex-1">
          <div className="relative">
            <input
              type="text"
              placeholder="Buscar por nome ou email"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:text-white"
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
        </div>

        <div className="sm:w-48">
          <select
            value={selectedLevel}
            onChange={(e) => setSelectedLevel(e.target.value as any)}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 dark:border-gray-600 rounded-lg focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:text-white appearance-none"
          >
            <option value="all">Todos os Níveis</option>
            <option value={1}>Nível 1 (10%)</option>
            <option value={2}>Nível 2 (5%)</option>
            <option value={3}>Nível 3 (3%)</option>
          </select>
          <Filter className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
      </div>

      {/* Referrals List */}
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-sm">
        {filteredReferrals.length === 0 ? (
          <div className="p-8 text-center text-gray-500 dark:text-gray-400">
            Nenhum indicado encontrado
          </div>
        ) : (
          <div className="divide-y divide-gray-200 dark:divide-gray-700">
            {filteredReferrals.map((referral) => (
              <div key={referral.id} className="p-4">
                <div 
                  className="flex items-center justify-between cursor-pointer"
                  onClick={() => toggleReferralDetails(referral.id)}
                >
                  <div className="flex items-center space-x-4">
                    <div className="w-10 h-10 bg-gray-100 dark:bg-gray-700 rounded-full flex items-center justify-center">
                      <span className="text-gray-600 dark:text-gray-400 font-medium">
                        {referral.name.charAt(0)}
                      </span>
                    </div>
                    <div>
                      <h4 className="text-sm font-medium text-gray-900 dark:text-white">
                        {referral.name}
                      </h4>
                      <p className="text-sm text-gray-500 dark:text-gray-400">
                        {referral.email}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-4">
                    <span className={`px-2.5 py-0.5 rounded-full text-xs font-medium ${getLevelColor(referral.level)}`}>
                      Nível {referral.level}
                    </span>
                    {expandedReferral === referral.id ? (
                      <ChevronUp className="h-5 w-5 text-gray-400" />
                    ) : (
                      <ChevronDown className="h-5 w-5 text-gray-400" />
                    )}
                  </div>
                </div>

                {expandedReferral === referral.id && (
                  <div className="mt-4 pl-14 space-y-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <div className="p-4 bg-gray-50 dark:bg-gray-700/50 rounded-lg">
                        <div className="flex items-center space-x-2 mb-2">
                          <DollarSign className="h-5 w-5 text-gray-400" />
                          <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                            Investimentos
                          </span>
                        </div>
                        <p className="text-lg font-semibold text-gray-900 dark:text-white">
                          {formatCurrency(referral.investmentAmount)}
                        </p>
                      </div>

                      <div className="p-4 bg-gray-50 dark:bg-gray-700/50 rounded-lg">
                        <div className="flex items-center space-x-2 mb-2">
                          <CreditCard className="h-5 w-5 text-gray-400" />
                          <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                            Plano de Crédito
                          </span>
                        </div>
                        <div className="flex items-center">
                          {referral.creditPlan ? (
                            <CheckCircle className="h-5 w-5 text-green-500" />
                          ) : (
                            <XCircle className="h-5 w-5 text-red-500" />
                          )}
                          <span className="ml-2 text-sm text-gray-600 dark:text-gray-400">
                            {referral.creditPlan ? 'Ativo' : 'Inativo'}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-between text-sm text-gray-600 dark:text-gray-400">
                      <span>Data de Cadastro: {new Date(referral.registrationDate).toLocaleDateString('pt-BR')}</span>
                      <span>Status: {referral.status === 'active' ? 'Ativo' : 'Inativo'}</span>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AffiliateNetwork;