import { doc, updateDoc, collection, query, getDocs, getDoc } from 'firebase/firestore';
import { db } from '../config/firebase';

export interface User {
  id: string;
  name: string;
  email: string;
  type: 'investor' | 'affiliate';
  status: 'active' | 'inactive';
  isAdmin: boolean;
  createdAt: string;
}

// Buscar todos os usuários
export const getAllUsers = async (): Promise<User[]> => {
  try {
    const usersRef = collection(db, 'users');
    const snapshot = await getDocs(usersRef);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    } as User));
  } catch (error) {
    console.error('Erro ao buscar usuários:', error);
    throw error;
  }
};

// Verificar se o usuário é admin
export const isAdminUser = async (userId: string): Promise<boolean> => {
  if (!userId) {
    throw new Error('ID de usuário inválido.');
  }

  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    return userDoc.exists() && userDoc.data().isAdmin === true;
  } catch (error) {
    console.error('Erro ao verificar status de admin:', error);
    return false;
  }
};

// Tornar um usuário admin com verificação de PIN
export const makeUserAdmin = async (userId: string, pin: string): Promise<void> => {
  if (!userId || !pin) {
    throw new Error('Parâmetros inválidos.');
  }

  try {
    // Em um app real, o PIN deve ser armazenado de forma segura (hash, por exemplo)
    const ADMIN_PIN = '1234';

    if (pin !== ADMIN_PIN) {
      throw new Error('PIN inválido.');
    }

    await updateDoc(doc(db, 'users', userId), {
      isAdmin: true,
      updatedAt: new Date().toISOString()
    });
  } catch (error) {
    console.error('Erro ao tornar o usuário admin:', error);
    throw error;
  }
};

// Remover privilégios de admin com verificação de PIN
export const removeUserAdmin = async (userId: string, pin: string): Promise<void> => {
  if (!userId || !pin) {
    throw new Error('Parâmetros inválidos.');
  }

  try {
    const ADMIN_PIN = '1234';

    if (pin !== ADMIN_PIN) {
      throw new Error('PIN inválido.');
    }

    await updateDoc(doc(db, 'users', userId), {
      isAdmin: false,
      updatedAt: new Date().toISOString()
    });
  } catch (error) {
    console.error('Erro ao remover privilégios de admin:', error);
    throw error;
  }
};

// Atualizar status do usuário (ativo/inativo)
export const updateUserStatus = async (userId: string, status: 'active' | 'inactive'): Promise<void> => {
  if (!userId) {
    throw new Error('ID de usuário inválido.');
  }

  try {
    await updateDoc(doc(db, 'users', userId), {
      status,
      updatedAt: new Date().toISOString()
    });
  } catch (error) {
    console.error('Erro ao atualizar status do usuário:', error);
    throw error;
  }
};

// Aprovar afiliado
export const approveAffiliate = async (userId: string): Promise<void> => {
  if (!userId) {
    throw new Error('ID de usuário inválido.');
  }

  try {
    await updateDoc(doc(db, 'users', userId), {
      isAffiliate: true,
      affiliateStatus: 'approved',
      approvedAt: new Date().toISOString()
    });
  } catch (error) {
    console.error('Erro ao aprovar afiliado:', error);
    throw error;
  }
};

// Aprovar solicitação de retirada
export const approveWithdrawal = async (withdrawalId: string): Promise<void> => {
  if (!withdrawalId) {
    throw new Error('ID de retirada inválido.');
  }

  try {
    await updateDoc(doc(db, 'withdrawals', withdrawalId), {
      status: 'approved',
      processedAt: new Date().toISOString()
    });
  } catch (error) {
    console.error('Erro ao aprovar retirada:', error);
    throw error;
  }
};
