import React from 'react';
import type { TestimonialType } from '../types';

const testimonials: TestimonialType[] = [
  {
    id: 1,
    name: 'Sarah Johnson',
    role: 'Empresária',
    content: 'O HK Bank transformou a maneira como eu invisto. A plataforma é intuitiva e os rendimentos são excelentes.',
    image: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=80'
  },
  {
    id: 2,
    name: 'Miguel Silva',
    role: 'Profissional de TI',
    content: 'A experiência digital é perfeita. Consigo acompanhar meus investimentos em tempo real pelo celular.',
    image: 'https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=80'
  },
  {
    id: 3,
    name: 'Ana Oliveira',
    role: 'Consultora Financeira',
    content: 'Recomendo o HK Bank a todos os meus clientes. Os produtos são transparentes e a rentabilidade é consistente.',
    image: 'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-1.2.1&auto=format&fit=crop&w=200&q=80'
  }
];

const Testimonials = () => {
  return (
    <section id="testimonials" className="py-20 bg-purple-50 dark:bg-gray-800">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl font-bold text-gray-900 dark:text-white sm:text-4xl">
            O que nossos clientes dizem
          </h2>
          <p className="mt-4 text-lg text-gray-600 dark:text-gray-400">
            Não acredite apenas em nós - veja o que nossos investidores têm a dizer
          </p>
        </div>

        <div className="mt-20 grid grid-cols-1 gap-8 md:grid-cols-3">
          {testimonials.map((testimonial) => (
            <div
              key={testimonial.id}
              className="bg-white dark:bg-gray-700 p-6 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300"
            >
              <div className="flex items-center">
                <img
                  className="h-12 w-12 rounded-full object-cover"
                  src={testimonial.image}
                  alt={testimonial.name}
                />
                <div className="ml-4">
                  <h4 className="text-lg font-semibold text-gray-900 dark:text-white">{testimonial.name}</h4>
                  <p className="text-sm text-gray-600 dark:text-gray-400">{testimonial.role}</p>
                </div>
              </div>
              <p className="mt-4 text-gray-600 dark:text-gray-300 italic">"{testimonial.content}"</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Testimonials;