import { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds } from 'date-fns';

export const calculateInvestmentReturn = (amount: number, days: number): number => {
  // Base monthly rate of 10%
  const monthlyRate = 0.10;
  
  // Convert days to months for the calculation
  const months = days / 30;
  
  // Calculate final amount using compound interest
  const finalAmount = amount * Math.pow(1 + monthlyRate, months);
  
  // Calculate total profit
  const profit = finalAmount - amount;
  
  return profit;
};

export const calculateTimeBasedProfit = (
  startDate: string,
  amount: number,
  lockPeriod: number,
  timeframe: 'second' | 'minute' | 'hour' | 'day' | 'week' | 'month'
): number => {
  const start = new Date(startDate);
  const now = new Date();
  const totalProfit = calculateInvestmentReturn(amount, lockPeriod);
  
  // Calculate profit per second
  const totalSeconds = lockPeriod * 24 * 60 * 60;
  const profitPerSecond = totalProfit / totalSeconds;

  // Calculate elapsed time based on timeframe
  let elapsedTime: number;
  switch (timeframe) {
    case 'second':
      elapsedTime = differenceInSeconds(now, start);
      return profitPerSecond * elapsedTime;
    case 'minute':
      elapsedTime = differenceInMinutes(now, start);
      return profitPerSecond * elapsedTime * 60;
    case 'hour':
      elapsedTime = differenceInHours(now, start);
      return profitPerSecond * elapsedTime * 3600;
    case 'day':
      elapsedTime = differenceInDays(now, start);
      return profitPerSecond * elapsedTime * 86400;
    case 'week':
      elapsedTime = differenceInDays(now, start) / 7;
      return profitPerSecond * elapsedTime * 604800;
    case 'month':
      elapsedTime = differenceInDays(now, start) / 30;
      return profitPerSecond * elapsedTime * 2592000;
    default:
      return 0;
  }
};

export const calculateProjectedProfit = (
  amount: number,
  lockPeriod: number,
  timeframe: 'second' | 'minute' | 'hour' | 'day' | 'week' | 'month'
): number => {
  const totalProfit = calculateInvestmentReturn(amount, lockPeriod);
  
  // Calculate profit per second
  const totalSeconds = lockPeriod * 24 * 60 * 60;
  const profitPerSecond = totalProfit / totalSeconds;

  // Calculate projected profit based on timeframe
  switch (timeframe) {
    case 'second':
      return profitPerSecond;
    case 'minute':
      return profitPerSecond * 60;
    case 'hour':
      return profitPerSecond * 3600;
    case 'day':
      return profitPerSecond * 86400;
    case 'week':
      return profitPerSecond * 604800;
    case 'month':
      return profitPerSecond * 2592000;
    default:
      return 0;
  }
};

export const calculateCurrentProfit = (
  startDate: string,
  amount: number,
  lockPeriod: number
): number => {
  return calculateTimeBasedProfit(startDate, amount, lockPeriod, 'second');
};

export const calculateDailyProfit = (totalProfit: number, days: number): number => {
  return totalProfit / days;
};