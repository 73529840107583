import React, { useState } from 'react';
import { QrCode, Copy, Check, Smartphone } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';
import { enable2FA, verify2FA } from '../../services/security';

const TwoFactorSetup = () => {
  const [step, setStep] = useState<'intro' | 'setup' | 'verify'>('intro');
  const [qrCode, setQrCode] = useState('');
  const [secret, setSecret] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [copied, setCopied] = useState(false);
  const { currentUser } = useAuth();

  const handleEnable2FA = async () => {
    try {
      setLoading(true);
      setError('');
      const { qrCodeUrl, secretKey } = await enable2FA(currentUser!.uid);
      setQrCode(qrCodeUrl);
      setSecret(secretKey);
      setStep('setup');
    } catch (error) {
      setError('Erro ao configurar 2FA. Tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  const handleVerification = async () => {
    try {
      setLoading(true);
      setError('');
      await verify2FA(currentUser!.uid, verificationCode);
      setStep('verify');
    } catch (error) {
      setError('Código inválido. Tente novamente.');
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(secret);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      console.error('Failed to copy:', error);
    }
  };

  return (
    <div className="p-6">
      {step === 'intro' && (
        <div>
          <div className="flex items-center space-x-3 mb-4">
            <Smartphone className="h-6 w-6 text-purple-600 dark:text-purple-400" />
            <h2 className="text-lg font-semibold text-gray-900 dark:text-white">
              Autenticação em Dois Fatores (2FA)
            </h2>
          </div>
          <p className="text-gray-600 dark:text-gray-400 mb-6">
            Adicione uma camada extra de segurança à sua conta usando autenticação em dois fatores.
          </p>
          <button
            onClick={handleEnable2FA}
            disabled={loading}
            className="bg-purple-600 text-white px-4 py-2 rounded-lg hover:bg-purple-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
          >
            {loading ? 'Configurando...' : 'Ativar 2FA'}
          </button>
        </div>
      )}

      {step === 'setup' && (
        <div>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-4">
            Configure o Autenticador
          </h3>
          <div className="space-y-6">
            <div className="bg-gray-50 dark:bg-gray-700 p-4 rounded-lg">
              <div className="flex justify-center mb-4">
                <img src={qrCode} alt="QR Code" className="w-48 h-48" />
              </div>
              <div className="flex items-center justify-between p-2 bg-white dark:bg-gray-800 rounded">
                <code className="text-sm text-gray-800 dark:text-gray-200">{secret}</code>
                <button
                  onClick={copyToClipboard}
                  className="text-purple-600 hover:text-purple-700 dark:text-purple-400"
                >
                  {copied ? <Check className="h-5 w-5" /> : <Copy className="h-5 w-5" />}
                </button>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1">
                Código de Verificação
              </label>
              <input
                type="text"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                placeholder="Digite o código do seu autenticador"
                className="w-full px-3 py-2 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm focus:ring-purple-500 focus:border-purple-500 dark:bg-gray-700 dark:text-white"
              />
            </div>

            {error && (
              <p className="text-sm text-red-600 dark:text-red-400">{error}</p>
            )}

            <button
              onClick={handleVerification}
              disabled={loading || !verificationCode}
              className="w-full bg-purple-600 text-white px-4 py-2 rounded-lg hover:bg-purple-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loading ? 'Verificando...' : 'Verificar e Ativar'}
            </button>
          </div>
        </div>
      )}

      {step === 'verify' && (
        <div className="text-center">
          <div className="mb-4">
            <div className="mx-auto w-12 h-12 bg-green-100 dark:bg-green-900/50 rounded-full flex items-center justify-center">
              <Check className="h-6 w-6 text-green-600 dark:text-green-400" />
            </div>
          </div>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white mb-2">
            2FA Ativado com Sucesso!
          </h3>
          <p className="text-gray-600 dark:text-gray-400">
            Sua conta agora está protegida com autenticação em dois fatores.
          </p>
        </div>
      )}
    </div>
  );
};

export default TwoFactorSetup;