export const calculateLoan = (
  amount: number,
  installments: number,
  interestRate: number
) => {
  // Calculate monthly payment using the formula: PMT = P * (r * (1 + r)^n) / ((1 + r)^n - 1)
  // Where: PMT = monthly payment, P = principal, r = monthly interest rate, n = number of payments
  const monthlyRate = 0.012; // 1.2% monthly interest rate
  const installmentAmount = (
    amount *
    (monthlyRate * Math.pow(1 + monthlyRate, installments)) /
    (Math.pow(1 + monthlyRate, installments) - 1)
  );
  
  const totalAmount = installmentAmount * installments;

  return {
    installmentAmount: Math.round(installmentAmount * 100) / 100,
    totalAmount: Math.round(totalAmount * 100) / 100
  };
};