import React from 'react';

interface LogoProps {
  className?: string;
  size?: 'sm' | 'md' | 'lg';
}

const Logo: React.FC<LogoProps> = ({ className = '', size = 'md' }) => {
  const sizes = {
    sm: 'text-xl',
    md: 'text-2xl',
    lg: 'text-3xl'
  };

  return (
    <div className={`flex items-center justify-center ${className}`}>
      <svg 
        className={`text-purple-600 dark:text-purple-400 ${
          size === 'sm' ? 'h-6 w-6' : 
          size === 'md' ? 'h-8 w-8' : 
          'h-10 w-10'
        }`}
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"
        />
      </svg>
      <span className={`ml-2 font-bold text-purple-600 dark:text-purple-400 ${sizes[size]}`}>
        HK Bank
      </span>
    </div>
  );
};

export default Logo;