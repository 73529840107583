import React from 'react';
import { Users, DollarSign, TrendingUp, Link as LinkIcon } from 'lucide-react';
import { formatCurrency } from '../../utils/format';

interface AffiliateMetricsProps {
  data?: {
    totalReferrals: number;
    activeReferrals: number;
    clickCount: number;
    totalEarnings: number;
    conversionRate: number;
  };
}

const AffiliateMetrics: React.FC<AffiliateMetricsProps> = ({ data }) => {
  const metrics = [
    {
      label: 'Cadastros Realizados',
      value: data?.totalReferrals || 0,
      change: '0%',
      positive: true,
      icon: Users
    },
    {
      label: 'Cadastros Ativos',
      value: data?.activeReferrals || 0,
      change: '0%',
      positive: true,
      icon: TrendingUp
    },
    {
      label: 'Cliques no Link',
      value: data?.clickCount || 0,
      change: '0%',
      positive: true,
      icon: LinkIcon
    },
    {
      label: 'Comissões Totais',
      value: formatCurrency(data?.totalEarnings || 0),
      change: '0%',
      positive: true,
      icon: DollarSign
    }
  ];

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-6">
      {metrics.map((metric, index) => {
        const Icon = metric.icon;
        return (
          <div key={index} className="p-4 bg-gray-50 dark:bg-gray-700/50 rounded-lg">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Icon className="h-4 w-4 sm:h-5 sm:w-5 text-purple-600 dark:text-purple-400" />
                <span className="ml-2 text-xs sm:text-sm font-medium text-gray-600 dark:text-gray-400">
                  {metric.label}
                </span>
              </div>
              <span className={`text-xs sm:text-sm ${
                metric.positive ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'
              }`}>
                {metric.change}
              </span>
            </div>
            <p className="mt-2 text-lg sm:text-2xl font-semibold text-gray-900 dark:text-white">
              {metric.value}
            </p>
          </div>
        );
      })}
    </div>
  );
};

export default AffiliateMetrics;